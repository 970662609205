import React, { useEffect, useState, useMemo } from "react";
import Dashboard from "./Dashboard";
import { Box, Modal, Fade, Backdrop } from "@mui/material";
import Axios from "axios";
import ApiConfig from "../Config/APIConfig";
import { AiOutlinePlus } from "react-icons/ai";
import { toast } from "react-toastify";
import axios from "axios";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24
};
const LeaveCategory = () => {
  const [token, setToken] = useState("")
  useEffect(() => {
    setToken(JSON.parse(localStorage.getItem("Att_token")))
  }, []);
  const [modalType, setModalType] = useState("");
  const [leaveData, setLeaveData] = useState([]);
  const [counter, setCounter] = useState(0);
  const handleLeaveData = async () => {
    try {
      const res = await Axios.get(ApiConfig.listLeaveTypes);
      if (res.status === 200) {
        setLeaveData(res?.data?.data);
      }
    } catch (error) { }
  };
  // console.log("sdsfsdfdfx", leaveData);
  useEffect(() => {
    handleLeaveData();
  }, [counter]);

  const [categoryData, setCategoryData] = useState({
    leave_type_name: "",
    leave_type_code: "",
    leave_type_default_credit: "",
    leave_type_description: "",
  });
  const [categoryEditData, setCategoryEditData] = useState();
  const handleViewEdit = (values) => {
    setCategoryEditData({
      leave_type_id: values?.leave_type_id,
      leave_type_name: values?.leave_type_name,
      leave_type_code: values?.leave_type_code,
      leave_type_default_credit: values?.leave_type_default_credit,
      leave_type_description: values?.leave_type_description,
    });
  };
  const handleAddInsert = (e) => {
    const { name, value } = e.target;
    setCategoryData({
      ...categoryData,
      [name]: value,
    });
  };
  const handleEditInsert = (e) => {
    const { name, value } = e.target;
    setCategoryEditData({
      ...categoryEditData,
      [name]: value,
    });
  };
  // console.log("dsfsdfsdf", categoryEditData);
  const handleAddLeaveCategory = async () => {
    try {
      const res = await axios.post(ApiConfig.addLeaveType, categoryData);
      if (res.status === 200) {
        handleLeaveData();
        setLeaveOpen()
        toast.success("Leave Category Added");
      }
    } catch (error) {
      toast.error("Something went Wrong");
    }
  };

  const handleEditCategory = async () => {
    try {
      const res = await Axios.post(ApiConfig.editLeaveType, categoryEditData, {
        headers: {
          Authorization: "Bearer " + token
        }
      });
      if (res.data.code === "200") {
        handleLeaveData();
        setLeaveOpen()
        toast.success("Leave catgory successfully updated");
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  const handleLeaveStatus = async (val) => {
    let { leave_type_id, leave_type_status } = val;
    if (leave_type_status === 0) {
      leave_type_status = 1
    } else {
      leave_type_status = 0
    }
    await axios.post(ApiConfig.changeLeaveTypeStatus, {
      "leave_type_id": leave_type_id,
      "leave_type_status": leave_type_status
    }, {
      headers: {
        Authorization: "Bearer " + token
      }
    }).then((res) => {
      // console.log(res)
      if (res?.data?.code === "200") {
        setCounter(c => c + 1)
        toast.success(res?.data?.message)
      } else {
        toast.error(res?.data?.message)
      }
    }).catch((err) => {
      toast.error(err.response.data.message)
    })
  }
  const [leaveOpen, setLeaveOpen] = useState(false);
  const leaveCatOpen = () => setLeaveOpen(true);
  return (
    <div>
      <Dashboard>
        <div className="page">
          <div className="page-wrapper" style={{ padding: "0px 10px" }}>
            <div className="container-lg container-main">
              <div className="page-header d-print-none mb-3">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                  }}
                  className="btnContainer"
                >
                  <h2>Leave Category List</h2>
                  <button
                    onClick={() => {
                      setModalType("Add");
                      leaveCatOpen()
                    }}
                    className="btn btn-primary"

                  >
                    <AiOutlinePlus />
                    &nbsp; Add Leave Category
                  </button>
                </div>
              </div>
            </div>
            <div className="card container-lg container-main">
              <div className="table-responsive">
                <table className="table table-vcenter table-mobile-md card-table" style={{ minWidth: "900px" }}>
                  <thead>
                    <tr>
                      <th>Leave ID</th>
                      <th>Leave Code</th>
                      <th>Leave Name</th>
                      <th>Leave Default Credit</th>
                      <th>Leave Description</th>
                      <th>Leave Status</th>
                      <th>
                        <div align="center">Actions</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {leaveData &&
                      leaveData?.map((values, i) => {
                        return (
                          <tr key={i}>
                            <td className="text" data-label="Role">
                              {values.leave_type_id}
                            </td>

                            <td className="text" data-label="Role">
                              {values.leave_type_code}
                            </td>
                            <td className="text" data-label="Role">
                              {values.leave_type_name}
                            </td>
                            <td className="text" data-label="Role">
                              {values.leave_type_default_credit}
                            </td>
                            <td className="text" data-label="Role">
                              <div className="textTruncate" style={{ maxWidth: "200px" }}>
                                {values.leave_type_description}
                              </div>
                            </td>
                            <td className="text" data-label="Role">
                              {values.leave_type_status == 1 ? (
                                <button
                                  className="btn btn-success ms-auto"
                                  onClick={() => handleLeaveStatus(values)}
                                >
                                  Active
                                </button>
                              ) :
                                (<button
                                  className="btn btn-danger ms-auto"
                                  onClick={() => handleLeaveStatus(values)}
                                >
                                  Deactive
                                </button>
                                )}
                            </td>
                            <td>
                              <div className="btn-list flex-nowrap">
                                <div className="dropdown">
                                  <button className="btn dropdown-toggle align-text-top" data-bs-toggle="dropdown" aria-expanded="false">
                                    Actions
                                  </button>
                                  <div className="dropdown-menu dropdown-menu-end">
                                    {/* <div
                                      className="dropdown-item"
                                      onClick={() => {
                                        setCategoryEditData(values);
                                        setModalType("View");
                                      }}
                                    >
                                      View
                                    </div> */}
                                    <div
                                      className="dropdown-item"
                                      onClick={() => {
                                        setCategoryEditData(values);
                                        setModalType("Edit");
                                        setLeaveOpen(true);
                                      }}
                                    >
                                      Edit
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={leaveOpen}
              onClose={() => setLeaveOpen()}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >
              <Fade in={leaveOpen}>
                <Box sx={style}>
                  <div className="modal-header">
                    <h5 className="modal-title">{modalType} Leave category</h5>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <label className="form-label">Leave Type Name :</label>
                        <input
                          type="text"
                          readOnly={modalType === "View" ? true : false}
                          className="form-control"
                          onChange={modalType === "Add" ? handleAddInsert : modalType === "Edit" ? handleEditInsert : null}
                          value={modalType === "Add" ? categoryData?.leave_type_name : modalType === "Edit" || modalType === "View" ? categoryEditData?.leave_type_name : null}
                          name="leave_type_name"
                          placeholder="Enter Leave Type Name"
                        />
                      </div>
                      <div className="col-md-12 mb-3">
                        <label className="form-label">Leave Code :</label>
                        <input
                          type="text"
                          className="form-control"
                          name="leave_type_code"
                          onChange={modalType === "Add" ? handleAddInsert : modalType === "Edit" ? handleEditInsert : null}
                          value={modalType === "Add" ? categoryData?.leave_type_code : modalType === "Edit" || modalType === "View" ? categoryEditData?.leave_type_code : null}
                          readOnly={modalType === "View" ? true : false}
                          placeholder="Enter Leave Code"
                        />
                      </div>
                      <div className="col-md-12 mb-3">
                        <label className="form-label">Leave Type Description :</label>
                        <input
                          type="text"
                          className="form-control"
                          name="leave_type_description"
                          onChange={modalType === "Add" ? handleAddInsert : modalType === "Edit" ? handleEditInsert : null}
                          value={modalType === "Add" ? categoryData?.leave_type_description : modalType === "Edit" || modalType === "View" ? categoryEditData?.leave_type_description : null}
                          readOnly={modalType === "View" ? true : false}
                          placeholder="Enter Leave Type Description"
                        />
                      </div>
                      <div className="col-md-12 mb-3">
                        <label className="form-label">Leave Default Credit :</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={modalType === "Add" ? handleAddInsert : modalType === "Edit" ? handleEditInsert : null}
                          value={modalType === "Add" ? categoryData?.leave_type_default_credit : modalType === "Edit" || modalType === "View" ? categoryEditData?.leave_type_default_credit : null}
                          readOnly={modalType === "View" ? true : false}
                          name="leave_type_default_credit"
                          placeholder="Enter Leave Default Credit"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <div className="btnContainer">
                      <button
                        className="btn ms-auto"
                        onClick={() => {
                          setLeaveOpen(false)
                        }}
                      >
                        Cancel
                      </button>
                      {modalType === "Add" || modalType === "Edit" ? (
                        <button
                          className="btn btn-primary ms-auto"
                          // onClick={handleAddLeaveCategory}
                          onClick={modalType === "Add" ? handleAddLeaveCategory : modalType === "Edit" ? handleEditCategory : null}
                        >
                          Submit
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </Box>
              </Fade>
            </Modal>
          </div>
        </div>
      </Dashboard >
    </div >
  );
};

export default LeaveCategory;
