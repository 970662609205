import React, { useState, useEffect, useMemo } from "react";
import Dashboard from "../Dashboard";
import { Formik, Form, Field } from "formik"
import { addCompany, addBranch } from "../schema"
import { Box, Drawer } from "@mui/material";
import ApiConfig from "../../Config/APIConfig";
import axios from "axios";
import { toast } from "react-toastify";
import PageHead from "../PageHead";
const Company = () => {
  const [token, setToken] = useState("")
  useEffect(() => {
    setToken(JSON.parse(localStorage.getItem("Att_token")))
  }, []);
  // console.log(token)
  const [drawerHead, setDrawerHead] = useState("");
  // console.log(drawerHead)
  const [editData, setEditData] = useState("");
  const [companyOpen, setCompanyOpen] = useState(false);
  const [branchOpen, setBranchOpen] = useState(false);
  const [companyId, setCompanyId] = useState({})
  const [companyList, setCompanyList] = useState([]);
  // console.log(editData)
  const getCompany = async () => {
    if (token) {
      await axios.get(ApiConfig.getCompanies, {
        headers: {
          Authorization: "Bearer " + token
        }
      }).then((res) => {
        // console.log(res)
        res.data.code === "200" ? setCompanyList(res.data.data) : setCompanyList([])
      }).catch((err) => {
        console.log(err)
      })
    }
  }
  useEffect(() => {
    getCompany();
  }, [token]);
  const [getBranch, setGetBranch] = useState([]);
  const getBranchByCompany = async () => {
    if (token) {
      await axios.get(`${ApiConfig.getBranches}&company_id=${companyId?.companyId}`, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then((res) => {
        res?.data?.code === "200" ? setGetBranch(res?.data?.data) : setGetBranch([])
        // console.log(res)
      }).catch((err) => {
        console.log(err)
      })
    }
  }
  useEffect(() => { getBranchByCompany() }, [token, companyId?.companyId])
  const initialField = {
    company_name: editData?.company_name,
    company_location: editData?.company_location,
    company_gst: editData?.company_gst,
    company_email: editData?.company_email,
    company_website: editData?.company_website
  }
  useMemo(() => !companyOpen ? setEditData("") : null, [companyOpen])

  const handleAddCompany = async (val) => {
    await axios.post(ApiConfig.addCompany, val, {
      headers: {
        Authorization: "Bearer " + token
      }
    }).then((res) => {
      // console.log(res)
      if (res?.data?.code === "200") {
        toast.success(res?.data?.message);
        getCompany();
        setCompanyOpen();
      } else {
        toast.error(res?.data?.message);
      }
    }).catch((err) => {
      toast.error(err?.response?.data?.message);
    })
  }
  const handleEditCompany = async (val) => {
    await axios.post(`${ApiConfig.updateCompany}&company_id=${editData?.company_id}`, val, {
      headers: {
        Authorization: "Bearer " + token
      }
    }).then((res) => {
      // console.log(res)
      if (res?.data?.code === "200") {
        toast.success(res?.data?.message);
        getCompany();
        setCompanyOpen();
      } else {
        toast.error(res?.data?.message);
      }
    }).catch((err) => {
      toast.error(err?.response?.data?.message);
    })
  }
  const [addBranchOpen, setAddBranchOpen] = useState(false)
  const [branchData, setBranchData] = useState("")
  const branchField = {
    company_id: branchData?.company_id,
    branch_name: branchData?.branch_name,
    branch_location: branchData?.branch_location,
    branch_email: branchData?.branch_email,
    branch_manager: branchData?.branch_manager
  }

  useMemo(() => !branchOpen ? setBranchData("") : null, [branchOpen])
  const handleAddBranch = async (val) => {
    await axios.post(ApiConfig.addBranch, val, {
      headers: {
        Authorization: "Bearer " + token
      }
    }).then((res) => {
      // console.log(res)
      if (res.data.code === "200") {
        toast.success(res.data.message);
        setAddBranchOpen()
      }
    }).catch((err) => {
      toast.error(err.response.data.message)
    })
  }
  const handleEditBranch = async (val) => {
    await axios.post(`${ApiConfig.updateBranch}&branch_id=${branchData?.branch_id}`, val, {
      headers: {
        Authorization: "Bearer " + token
      }
    }).then((res) => {
      // console.log(res)
      if (res.data.code === "200") {
        toast.success(res.data.message);
        setAddBranchOpen();
        getBranchByCompany()
      }
    }).catch((err) => {
      toast.error(err.response.data.message)
    })
  }
  const [empData, setEmpData] = useState([]);
  // console.log(branchData?.company_id)
  const getEmployee = async (val) => {

    await axios.get(`${ApiConfig?.getEmployeeT}&employee_company=${branchData?.company_id === undefined ? val : branchData?.company_id}`, {
      headers: {
        Authorization: "Bearer " + token
      }
    }).then((res) => {
      res.data.code === "200" ? setEmpData(res.data.data) : setEmpData([])
    }).catch((err) => {
      throw new Error(err)
    })
  }
  useEffect(() => {
    getEmployee();
  }, [branchData?.company_id]);
  return (
    <div>
      <Dashboard>
        <PageHead title="Attendify || Candidate" />
        <div className="page">
          <div className="page-wrapper" style={{ padding: "0px 10px" }}>
            <div className="container-lg container-main">
              <div className="page-header d-print-none mb-3">
                <div
                  style={{
                    display: "flex",
                    flexwrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      marginRight: "15px",
                    }}
                  >
                    <h2>Company Management</h2>
                  </div>
                  <div className="d-print-none">
                    <div className="btn-list">
                      <button
                        className="btn btn-primary d-none d-sm-inline-block"
                        onClick={() => setCompanyOpen(true)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <line x1="12" y1="5" x2="12" y2="19" />
                          <line x1="5" y1="12" x2="19" y2="12" />
                        </svg>
                        Add Company
                      </button>
                      <button
                        className="btn btn-success d-none d-sm-inline-block"
                        onClick={() => { setAddBranchOpen(true); setDrawerHead("Add") }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <line x1="12" y1="5" x2="12" y2="19" />
                          <line x1="5" y1="12" x2="19" y2="12" />
                        </svg>
                        Add Branch
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card container-lg container-main">
              <div className="table-responsive">
                <table className="table table-vcenter card-table">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Company Name</th>
                      <th>Company Loc</th>
                      <th>Company Email</th>
                      <th>Company Gst</th>
                      <th>Company Website</th>
                      <th>
                        <div align="center">Actions</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      companyList?.map((val, i) => {
                        return (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{val?.company_name}</td>
                            <td>{val?.company_location}</td>
                            <td>{val?.company_email}</td>
                            <td>{val?.company_gst}</td>
                            <td>{val?.company_website}</td>
                            <td align="center"><span className="mx-3">
                              <i onClick={() => { setEditData(val); setDrawerHead("Edit"); setCompanyOpen(true) }} className="fas fa-edit text-success"></i>
                            </span>
                              <span >
                                <button onClick={() => { setBranchOpen(true); setCompanyId({ companyId: val?.company_id, companyName: val?.company_name }); }} className="btn">
                                  View Branch
                                </button>
                              </span>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
              <div className="card-footer d-flex align-items-center">
                <p className="m-0 text-muted">
                  Showing <span>1</span> to <span>8</span> of <span>16</span> entries
                </p>
              </div>
            </div>
          </div>
        </div>
      </Dashboard >
      {/* Company Add */}
      <Drawer anchor="right" open={companyOpen} className="modal-blur" onClose={() => setCompanyOpen()}>
        <Box width="40rem">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Company</h5>
            </div>

            <Formik initialValues={initialField}
              initialTouched={{
                field: true,
              }}
              validationSchema={addCompany}
              enableReinitialize
              onSubmit={(values) => {
                if (drawerHead === "Edit") {
                  return handleEditCompany(values)
                } else {
                  return handleAddCompany(values)
                }
              }
              }
            >
              {({ errors, handleBlur, handleChange, touched, values }) => {
                // console.log(errors);
                return (
                  <Form>
                    <div className="modal-body">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <label className="form-label" htmlFor="company_name">Company Name</label>
                            <Field type="text" onChange={handleChange} onBlur={handleBlur} value={values?.company_name || ""} name="company_name" id="company_name" className="form-control" placeholder="Enter Company Name" />
                            {errors?.company_name && touched.company_name ? <p className="form-valid">{errors?.company_name}</p> : null}
                          </div>
                          <div className="col-md-6">
                            <label className="form-label" htmlFor="company_location">Company Location</label>
                            <Field type="text" onChange={handleChange} onBlur={handleBlur} value={values?.company_location || ""} name="company_location" id="company_location" className="form-control" placeholder="Enter Company Location" />
                            {errors?.company_location && touched.company_location ? <p className="form-valid">{errors?.company_location}</p> : null}
                          </div>
                          <div className="col-md-6">
                            <label className="form-label" htmlFor="company_email">Company Email</label>
                            <Field type="text" onChange={handleChange} onBlur={handleBlur} value={values?.company_email || ""} name="company_email" id="company_email" className="form-control" placeholder="Enter Company Email" />
                            {errors?.company_email && touched.company_email ? <p className="form-valid">{errors?.company_email}</p> : null}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label" htmlFor="company_GST">Company GST</label>
                            <Field type="text" onChange={handleChange} onBlur={handleBlur} value={values?.company_gst || ""} name="company_gst" id="company_GST" className="form-control" placeholder="Enter Company GST" />
                            {errors?.company_gst && touched.company_gst ? <p className="form-valid">{errors?.company_gst}</p> : null}
                          </div>
                          <div className="col-md-6">
                            <label className="form-label" htmlFor="company_website">Company Website</label>
                            <Field type="text" onChange={handleChange} onBlur={handleBlur} value={values?.company_website || ""} name="company_website" id="company_website" className="form-control" placeholder="Enter Company Website" />
                            {errors?.company_website && touched.company_website ? <p className="form-valid">{errors?.company_website}</p> : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={() => setCompanyOpen()}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-primary"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                )
              }
              }
            </Formik>
          </div>
        </Box>
      </Drawer>

      {/* Branch Details */}
      <Drawer anchor="right" open={branchOpen} className="modal-blur" onClose={() => setBranchOpen()}>
        <Box width="45rem">
          <div className="card container-lg container-main">
            <h2 className="mx-2 my-2">Branch of <span className="text-success">{companyId?.companyName}</span></h2>
            <div className="table-responsive">
              <table className="table table-vcenter card-table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Branch Name</th>
                    <th>Branch Email</th>
                    <th>Branch Location</th>
                    <th>Branch Status</th>
                    <th>
                      <div align="center">Actions</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {getBranch?.length === 0 && <p>No Branch Found</p>}
                  {
                    getBranch?.map((val, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{val?.branch_name}</td>
                          <td>{val?.branch_email}</td>
                          <td>{val?.branch_location}</td>
                          <td>{val?.branch_status === 1 ? <i className="far fa-check-circle text-success"></i> : <i className="far fa-times-circle text-danger"></i>}</td>
                          <td align="center"><i onClick={() => { setBranchData(val); setDrawerHead("Edit"); setAddBranchOpen(true) }} className="fas fa-edit text-success"></i></td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </Box>
      </Drawer>

      {/* Branch Add */}
      <Drawer anchor="right" open={addBranchOpen} className="modal-blur" onClose={() => setAddBranchOpen()}>
        <Box width="40rem">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{drawerHead} Branch</h5>
            </div>

            <Formik initialValues={branchField}
              initialTouched={{
                field: true,
              }}
              validationSchema={addBranch}
              enableReinitialize
              onSubmit={(values) => {
                if (drawerHead === "Edit") {
                  return handleEditBranch(values)
                } else {
                  return handleAddBranch(values)
                }
              }
              }
            >
              {({ errors, handleBlur, handleChange, touched, values }) => {
                // console.log(errors);
                return (
                  <Form>
                    <div className="modal-body">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <label className="form-label" htmlFor="company">Choose Company</label>
                            <Field as="select" onChange={(e) => {
                              handleChange(e);
                              getEmployee(e.target.value)
                            }} onBlur={handleBlur} value={values?.company_id || ""} name="company_id" id="company" className="form-control">
                              <option value="">---Choose Company---</option>
                              {
                                companyList?.map((val, i) => {
                                  return (
                                    <option key={i} value={val?.company_id}>{val?.company_name}</option>
                                  )
                                })
                              }
                            </Field>
                            {errors?.company_id && touched.company_id ? <p className="form-valid">{errors?.company_id}</p> : null}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label" htmlFor="branch_name">Branch Name</label>
                            <Field type="text" onChange={handleChange} onBlur={handleBlur} value={values?.branch_name || ""} name="branch_name" id="branch_name" className="form-control" placeholder="Enter Company Name" />
                            {errors?.branch_name && touched.branch_name ? <p className="form-valid">{errors?.branch_name}</p> : null}
                          </div>
                          {/* <div className="col-md-6">
                            <label className="form-label" htmlFor="branch_manager">Branch Manager</label>
                          
                            <Field as="select" onChange={handleChange} onBlur={handleBlur} value={values?.branch_manager || ""} name="branch_manager" id="branch_manager" className="form-select">
                              <option value="">---Choose Employee---</option>
                              {
                                empData?.map((val, i) => {
                                  return (
                                    <option key={i} value={val?.employee_name}>{val?.employee_name}</option>
                                  )
                                })
                              }
                            </Field>
                          </div> */}
                          <div className="col-md-6">
                            <label className="form-label" htmlFor="branch_email">Branch Email</label>
                            <Field type="text" onChange={handleChange} onBlur={handleBlur} value={values?.branch_email || ""} name="branch_email" id="branch_email" className="form-control" placeholder="Enter Company Email" />
                            {errors?.branch_email && touched.branch_email ? <p className="form-valid">{errors?.branch_email}</p> : null}
                          </div>
                          <div className="col-md-6">
                            <label className="form-label" htmlFor="branch_location">Branch Location</label>
                            <Field type="text" onChange={handleChange} onBlur={handleBlur} value={values?.branch_location || ""} name="branch_location" id="branch_location" className="form-control" placeholder="Enter Company Website" />
                            {errors?.branch_location && touched.branch_location ? <p className="form-valid">{errors?.branch_location}</p> : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={() => setCompanyOpen()}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-primary"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                )
              }
              }
            </Formik>
          </div>
        </Box>
      </Drawer>
    </div >
  );
};

export default Company;
