import { useState, useEffect, useContext } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import DataContext from '../Context/Context'
import PageHead from "./PageHead";
import Axios from 'axios';
import ApiConfig from "../Config/APIConfig";
const Dashboard = ({ children }) => {
  const navigate = useNavigate();
  const context = useContext(DataContext);
  const [token, setToken] = useState("")
  useEffect(() => {
    setToken(JSON.parse(localStorage.getItem("Att_token")))
  }, []);
  const [company, setCompany] = useState([]);
  const getCompany = async () => {
    if (token) {
      await Axios.get(ApiConfig.getCompanies, {
        headers: {
          Authorization: "Bearer " + token
        }
      }).then((res) => {
        // console.log(res)
        res?.data?.code === "200" ? setCompany(res?.data?.data) : setCompany([]);
      }).catch((err) => {
        if (err.response.data.code === "403") {
          localStorage.removeItem("Employee_Details");
          localStorage.removeItem("Att_token");
          navigate("/login")
        }
      })
    }
  }
  useEffect(() => {
    getCompany()
  }, [token]);
  const handleLogout = () => {
    localStorage.removeItem("Att_token");
    localStorage.removeItem("Employee_Details");
    navigate("/login");
    context?.setloginData("")
  };
  const { loginData } = context;
  // console.log(loginData)
  // const loginData = JSON.parse(Employee_Details);
  // const loginData = JSON.parse(localStorage.getItem("Employee_Details")).type;
  // console.log("sdfdxzfgxcg", loginData);

  return (
    <div>
      <PageHead title="Attendify || Dashboard" />
      <header className="navbar navbar-expand-md navbar-light d-print-none">
        <div className="container-xl d-flex justify-content-end">
          <div className="navbar-nav flex-row order-md-last">
            <div className="d-none d-md-flex">

              <a
                href="?theme=light"
                className="nav-link px-0 hide-theme-light"
                title=""
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                data-bs-original-title="Enable light mode"
              >
                {/* <!-- Download SVG icon from http://tabler-icons.io/i/sun --> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <circle cx="12" cy="12" r="4"></circle>
                  <path d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7"></path>
                </svg>
              </a>
              <div className="nav-item dropdown d-none d-md-flex me-3">
                <a
                  href="#"
                  className="nav-link px-0"
                  data-bs-toggle="dropdown"
                  tabIndex="-1"
                  aria-label="Show notifications"
                >
                  {/* <!-- Download SVG icon from http://tabler-icons.io/i/bell --> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6"></path>
                    <path d="M9 17v1a3 3 0 0 0 6 0v-1"></path>
                  </svg>
                  <span className="badge bg-red"></span>
                </a>
                <div className="dropdown-menu dropdown-menu-arrow dropdown-menu-end dropdown-menu-card">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Last updates</h3>
                    </div>
                    <div className="list-group list-group-flush list-group-hoverable">
                      <div className="list-group-item">
                        <div className="row align-items-center">
                          <div className="col-auto">
                            <span className="status-dot status-dot-animated bg-red d-block"></span>
                          </div>
                          <div className="col text-truncate">
                            <a href="#" className="text-body d-block">
                              Example 1
                            </a>
                            <div className="d-block text-muted text-truncate mt-n1">
                              Change deprecated html tags to text decoration
                              classes (#29604)
                            </div>
                          </div>
                          <div className="col-auto">
                            <a href="#" className="list-group-item-actions">
                              {/* <!-- Download SVG icon from http://tabler-icons.io/i/star --> */}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon text-muted"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"></path>
                              </svg>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="list-group-item">
                        <div className="row align-items-center">
                          <div className="col-auto">
                            <span className="status-dot d-block"></span>
                          </div>
                          <div className="col text-truncate">
                            <a href="#" className="text-body d-block">
                              Example 2
                            </a>
                            <div className="d-block text-muted text-truncate mt-n1">
                              justify-content:between ⇒
                              justify-content:space-between (#29734)
                            </div>
                          </div>
                          <div className="col-auto">
                            <a href="#" className="list-group-item-actions show">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon text-yellow"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"></path>
                              </svg>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="list-group-item">
                        <div className="row align-items-center">
                          <div className="col-auto">
                            <span className="status-dot d-block"></span>
                          </div>
                          <div className="col text-truncate">
                            <a href="#" className="text-body d-block">
                              Example 3
                            </a>
                            <div className="d-block text-muted text-truncate mt-n1">
                              Update change-version.js (#29736)
                            </div>
                          </div>
                          <div className="col-auto">
                            <a href="#" className="list-group-item-actions">
                              {/* <!-- Download SVG icon from http://tabler-icons.io/i/star --> */}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon text-muted"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"></path>
                              </svg>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="list-group-item">
                        <div className="row align-items-center">
                          <div className="col-auto">
                            <span className="status-dot status-dot-animated bg-green d-block"></span>
                          </div>
                          <div className="col text-truncate">
                            <a href="#" className="text-body d-block">
                              Example 4
                            </a>
                            <div className="d-block text-muted text-truncate mt-n1">
                              Regenerate package-lock.json (#29730)
                            </div>
                          </div>
                          <div className="col-auto">
                            <a href="#" className="list-group-item-actions">
                              {/* <!-- Download SVG icon from http://tabler-icons.io/i/star --> */}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon text-muted"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"></path>
                              </svg>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="nav-item dropdown">
              <a
                href="#"
                className="nav-link d-flex lh-1 text-reset p-0"
                data-bs-toggle="dropdown"
                aria-label="Open user menu"
              >
                <span
                  className="avatar avatar-sm"
                  style={{
                    backgroundImage: "url(/static/avatars/000m.jpg)",
                  }}
                ></span>
                {/* here add avatar detil */}
                <div className="d-none d-xl-block ps-2">
                  {loginData?.name}
                  <div className="mt-1 small text-muted">{loginData?.employee_no}</div>
                </div>
              </a>
              <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                {/* <a href="#" className="dropdown-item">
                  Set status
                </a>
                <a href="#" className="dropdown-item">
                  Profile &amp; account
                </a>
                <a href="#" className="dropdown-item">
                  Feedback
                </a> */}
                <NavLink to="/updatepassword" className="dropdown-item">
                  Update Password
                </NavLink>
                <div className="dropdown-divider"></div>
                {/* <a href="#" className="dropdown-item">
                  Settings
                </a> */}
                <div className="dropdown-item" onClick={handleLogout}>
                  Logout
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="px-2">
        <div className="row">
          <div className="col-sm-12 col-md-2 col-lg-2 sidenav">
            {/* side bar */}
            <aside className="navbar navbar-vertical navbar-expand-lg newClass navbar-dark">
              <div className="container-fluid">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbar-menu"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <h1 className="navbar-brand navbar-brand-autodark">
                  <Link to="/homeDash">
                    <img
                      src="/static/abc.png"
                      alt="Tabler"
                      className="navbar-brand-image"
                      width="110"
                      height="32"
                    />
                  </Link>
                </h1>
                <div className="navbar-nav flex-row d-lg-none">
                  <div className="nav-item d-none d-lg-flex me-3">
                    <div className="btn-list"></div>
                  </div>
                  <div className="d-none d-lg-flex">
                    <a
                      href="?theme=dark"
                      className="nav-link px-0 hide-theme-dark"
                      title=""
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      data-bs-original-title="Enable dark mode"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z"></path>
                      </svg>
                    </a>
                    <a
                      href="?theme=light"
                      className="nav-link px-0 hide-theme-light"
                      title=""
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      data-bs-original-title="Enable light mode"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <circle cx="12" cy="12" r="4"></circle>
                        <path d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7"></path>
                      </svg>
                    </a>
                    <div className="nav-item dropdown d-none d-md-flex me-3">
                      <a
                        href="#"
                        className="nav-link px-0"
                        data-bs-toggle="dropdown"
                        tabIndex="-1"
                        aria-label="Show notifications"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6"></path>
                          <path d="M9 17v1a3 3 0 0 0 6 0v-1"></path>
                        </svg>
                        <span className="badge bg-red"></span>
                      </a>
                      <div className="dropdown-menu dropdown-menu-arrow dropdown-menu-end dropdown-menu-card">
                        <div className="card">
                          <div className="card-header">
                            <h3 className="card-title">Last updates</h3>
                          </div>
                          <div className="list-group list-group-flush list-group-hoverable">
                            <div className="list-group-item">
                              <div className="row align-items-center">
                                <div className="col-auto">
                                  <span className="status-dot status-dot-animated bg-red d-block"></span>
                                </div>
                                <div className="col text-truncate">
                                  <a href="#" className="text-body d-block">
                                    Example 1
                                  </a>
                                  <div className="d-block text-muted text-truncate mt-n1">
                                    Change deprecated html tags to text decoration
                                    classes (#29604)
                                  </div>
                                </div>
                                <div className="col-auto">
                                  <a href="#" className="list-group-item-actions">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="icon text-muted"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      strokeWidth="2"
                                      stroke="currentColor"
                                      fill="none"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                      ></path>
                                      <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"></path>
                                    </svg>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="list-group-item">
                              <div className="row align-items-center">
                                <div className="col-auto">
                                  <span className="status-dot d-block"></span>
                                </div>
                                <div className="col text-truncate">
                                  <a href="#" className="text-body d-block">
                                    Example 2
                                  </a>
                                  <div className="d-block text-muted text-truncate mt-n1">
                                    justify-content:between ⇒
                                    justify-content:space-between (#29734)
                                  </div>
                                </div>
                                <div className="col-auto">
                                  <a
                                    href="#"
                                    className="list-group-item-actions show"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="icon text-yellow"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      strokeWidth="2"
                                      stroke="currentColor"
                                      fill="none"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                      ></path>
                                      <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"></path>
                                    </svg>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="list-group-item">
                              <div className="row align-items-center">
                                <div className="col-auto">
                                  <span className="status-dot d-block"></span>
                                </div>
                                <div className="col text-truncate">
                                  <a href="#" className="text-body d-block">
                                    Example 3
                                  </a>
                                  <div className="d-block text-muted text-truncate mt-n1">
                                    Update change-version.js (#29736)
                                  </div>
                                </div>
                                <div className="col-auto">
                                  <a href="#" className="list-group-item-actions">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="icon text-muted"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      strokeWidth="2"
                                      stroke="currentColor"
                                      fill="none"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                      ></path>
                                      <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"></path>
                                    </svg>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="collapse navbar-collapse" id="navbar-menu">
                  <ul className="navbar-nav pt-lg-3">
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/homeDash">
                        <span className="nav-link-icon d-md-none d-lg-inline-block">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path
                              stroke="none"
                              d="M0 0h24v24H0z"
                              fill="none"
                            ></path>
                            <polyline points="5 12 3 12 12 3 21 12 19 12"></polyline>
                            <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"></path>
                            <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6"></path>
                          </svg>
                        </span>
                        <span className="nav-link-title">Home</span>
                      </NavLink>
                    </li>
                    {loginData?.type === 5 ? (
                      <li className="nav-item dropdown">
                        <div
                          className="nav-link dropdown-toggle"
                          href="#navbar-base"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="false"
                          role="button"
                          aria-expanded="false"
                        >
                          <span className="nav-link-icon d-md-none d-lg-inline-block">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <polyline points="12 3 20 7.5 20 16.5 12 21 4 16.5 4 7.5 12 3"></polyline>
                              <line x1="12" y1="12" x2="20" y2="7.5"></line>
                              <line x1="12" y1="12" x2="12" y2="21"></line>
                              <line x1="12" y1="12" x2="4" y2="7.5"></line>
                              <line x1="16" y1="5.25" x2="8" y2="9.75"></line>
                            </svg>
                          </span>
                          <span className="nav-link-title">
                            Candidate Management
                          </span>
                        </div>
                        <div className="dropdown-menu">
                          <div className="dropdown-menu-columns">
                            <div className="dropdown-menu-column">

                              <>
                                <NavLink className="dropdown-item" to="/candidateEnroll">
                                  Candidate Enrollment
                                </NavLink>
                                <NavLink className="dropdown-item" to="/internship">
                                  Internship
                                </NavLink>
                              </>
                            </div>
                          </div>
                        </div>
                      </li>
                    ) : null}
                    {loginData?.type === 5 ? (
                      <li className="nav-item dropdown">
                        <div
                          className="nav-link dropdown-toggle"
                          href="#navbar-base"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="false"
                          role="button"
                          aria-expanded="false"
                        >
                          <span className="nav-link-icon d-md-none d-lg-inline-block">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <polyline points="12 3 20 7.5 20 16.5 12 21 4 16.5 4 7.5 12 3"></polyline>
                              <line x1="12" y1="12" x2="20" y2="7.5"></line>
                              <line x1="12" y1="12" x2="12" y2="21"></line>
                              <line x1="12" y1="12" x2="4" y2="7.5"></line>
                              <line x1="16" y1="5.25" x2="8" y2="9.75"></line>
                            </svg>
                          </span>
                          <span className="nav-link-title">Branch Company</span>
                        </div>
                        <div className="dropdown-menu">
                          <div className="dropdown-menu-columns">
                            <div className="dropdown-menu-column">
                              {
                                company?.map((val, i) => {
                                  return (
                                    <NavLink key={i} className="dropdown-item" to={`/home/${val?.company_id}`}>
                                      {val?.company_name}
                                    </NavLink>
                                  )
                                })
                              }
                              {/* <NavLink className="dropdown-item" to={"/home/" + 1}>
                              Rechargekit
                            </NavLink>
                            <NavLink className="dropdown-item" to={"/home/" + 2}>
                              Refab
                            </NavLink>
                            <NavLink className="dropdown-item" to={"/home/" + 3}>
                              Renxt
                            </NavLink>
                            <NavLink className="dropdown-item" to={"/home/" + 4}>
                              Qubeinfo
                            </NavLink> */}
                            </div>
                          </div>
                        </div>
                      </li>
                    ) : null}

                    <li className="nav-item">
                      <NavLink className="nav-link" to="/attendance">
                        <span className="nav-link-icon d-md-none d-lg-inline-block">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path
                              stroke="none"
                              d="M0 0h24v24H0z"
                              fill="none"
                            ></path>
                            <polyline points="9 11 12 14 20 6"></polyline>
                            <path d="M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9"></path>
                          </svg>
                        </span>
                        <span className="nav-link-title">Attendance</span>
                      </NavLink>
                    </li>

                    <li className="nav-item dropdown">
                      <div

                        className="nav-link dropdown-toggle"
                        href="#navbar-base"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="false"
                        role="button"
                        aria-expanded="false"
                      >
                        <span className="nav-link-icon d-md-none d-lg-inline-block">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path
                              stroke="none"
                              d="M0 0h24v24H0z"
                              fill="none"
                            ></path>
                            <polyline points="12 3 20 7.5 20 16.5 12 21 4 16.5 4 7.5 12 3"></polyline>
                            <line x1="12" y1="12" x2="20" y2="7.5"></line>
                            <line x1="12" y1="12" x2="12" y2="21"></line>
                            <line x1="12" y1="12" x2="4" y2="7.5"></line>
                            <line x1="16" y1="5.25" x2="8" y2="9.75"></line>
                          </svg>
                        </span>
                        <span className="nav-link-title">
                          {loginData?.type === 5
                            ? "Leave Management"
                            : loginData != 5
                              ? "Leave Application"
                              : null}
                        </span>
                      </div>
                      <div className="dropdown-menu">
                        <div className="dropdown-menu-columns">
                          <div className="dropdown-menu-column">
                            <NavLink className="dropdown-item" to="/leavemgmt">
                              Leave Application
                            </NavLink>
                            {loginData?.type === 5 ? (
                              <NavLink className="dropdown-item" to="/leaveCategory">
                                Leave Category
                              </NavLink>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </li>

                    {loginData?.type === 5 ? (
                      <li className="nav-item dropdown">
                        <div
                          className="nav-link dropdown-toggle"
                          href="#navbar-base"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="false"
                          role="button"
                          aria-expanded="false"
                        >
                          <span className="nav-link-icon d-md-none d-lg-inline-block">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <polyline points="12 3 20 7.5 20 16.5 12 21 4 16.5 4 7.5 12 3"></polyline>
                              <line x1="12" y1="12" x2="20" y2="7.5"></line>
                              <line x1="12" y1="12" x2="12" y2="21"></line>
                              <line x1="12" y1="12" x2="4" y2="7.5"></line>
                              <line x1="16" y1="5.25" x2="8" y2="9.75"></line>
                            </svg>
                          </span>
                          <span className="nav-link-title">Company Management</span>
                        </div>
                        <div className="dropdown-menu">
                          <div className="dropdown-menu-columns">
                            <div className="dropdown-menu-column">
                              <NavLink className="dropdown-item" to="/company_management">
                                Manage Company
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </li>
                    ) : null}
                  </ul>
                </div>
              </div>
            </aside>
          </div>
          <div className="col-sm-12 col-md-10 col-lg-10">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
