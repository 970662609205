import Axios from "axios";
import { useState, useContext } from "react";
import DataContext from "../Context/Context";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ButtonCircleLoader from "../CircleLoading/ButtonCircleLoader";
import ApiConfig from "../Config/APIConfig";
const Login = () => {
  const navigate = useNavigate();
  const ContextData = useContext(DataContext)
  const [empID, setEmpID] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const res = await Axios.post(ApiConfig.login, {
        employee_no: empID,
        password: userPassword,
      });
      if (res?.status === 200) {
        setIsLoading(false);
        localStorage.setItem("Att_token", JSON.stringify(res?.data?.token));
        localStorage.setItem("Employee_Details", JSON.stringify(res?.data?.employee));
        toast.success("Login Successfully");
        ContextData?.setLoginCounter(ContextData?.loginCounter + 1)
        navigate("/homeDash");
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Incorrect login credentials");
    }
  };
  return (
    <div className="page page-center">
      <div className="container-tight py-4">
        <div className="text-center mb-4">
          <a href="." className="navbar-brand navbar-brand-autodark ">
            <img className="logo" src="./static/abc_black.png" alt="" />
          </a>
        </div>
        <form className="card card-md" autoComplete="off">
          <div className="card-body">
            <h2 className="card-title text-center mb-4">Employee account</h2>

            <div className="mb-3">
              <label className="form-label">Employee ID</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setEmpID(e.target.value);
                }}
                value={empID}
                placeholder="Enter Employee ID"
                autoComplete="off"
              />
            </div>
            <div className="mb-2">
              <label className="form-label">
                Password
              </label>
              <div className="input-group input-group-flat">
                <input
                  // type="password"
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  onChange={(e) => {
                    setUserPassword(e.target.value);
                  }}
                  placeholder="Password"
                  autoComplete="off"
                />

                <span className="input-group-text">
                  <div
                    // href="/"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    className="link-secondary"
                    title="Show password"
                    data-bs-toggle="tooltip"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <circle cx="12" cy="12" r="2" />
                      <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                    </svg>
                  </div>
                </span>
              </div>
            </div>
            <span className="form-label-description">
              <Link to="/forgetpassword">forgot password ?</Link>
            </span>
            <div className="mb-2">
              <label className="form-check">
                <input type="checkbox" className="form-check-input" />
                <span className="form-check-label">
                  Remember me on this device
                </span>
              </label>
            </div>
            <div className="form-footer">
              <button
                onClick={handleLogin}
                type="submit"
                className="btn btn-primary w-100"
              >
                Login {isLoading && <ButtonCircleLoader />}
              </button>
            </div>
          </div>
        </form>
        {/* <div className="text-center text-muted mt-3">
          Don't have account yet?{" "}
          <a href="/" tabIndex="-1">
            Sign up
          </a>
        </div> */}
      </div>
    </div>
  );
};

export default Login;
