export const degreeConfig = [
    {
        id: 1,
        degree: "10th"
    },
    {
        id: 2,
        degree: "12th"
    },
    {
        id: 3,
        degree: "Diploma"
    },
    {
        id: 4,
        degree: "B-Tech"
    },
    {
        id: 5,
        degree: "BCA"
    },
    {
        id: 6,
        degree: "MCA"
    },
    {
        id: 7,
        degree: "M-Tech"
    },
]
export const fileType=[
    {
        id: 1,
        name: "Photo"
    },
    {
        id: 2,
        name: "Adhar"
    },
    {
        id: 3,
        name: "Pan Card"
    },
    {
        id: 4,
        name: "Bank Passbook"
    },
]