import React, { useState, useEffect, useContext, useMemo } from "react";
import Dashboard from "./Dashboard";
import { Link } from "react-router-dom";
import DataContext from "../Context/Context";
import { Box, Drawer } from "@mui/material";
import { toast } from "react-toastify";
import moment from "moment";
import Axios from "axios";
import ApiConfig from "../Config/APIConfig";
import PageHead from "./PageHead";
import { Form, Formik, Field } from 'formik'
import { addLeave } from "./schema";
import Pagination from '@mui/material/Pagination';
const LeaveMgmt = () => {
  const [token, setToken] = useState("")
  useEffect(() => {
    setToken(JSON.parse(localStorage.getItem("Att_token")))
  }, []);
  const { loginData } = useContext(DataContext);
  const [page, setPage] = useState(1)
  // console.log(page)
  let currentDate = new Date().toJSON().slice(0, 10);
  // console.log(currentDate)

  const limit = 10
  const [leaveData, setLeaveData] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerData, setDrawerData] = useState("");
  const [drawerHead, setDrawerHead] = useState("");
  const [statusDrawerOpen, setStatusDrawerOpen] = useState(false);
  const [count, setCount] = useState("")
  // console.log(leaveData)
  const handleLeaveData = async () => {
    if (loginData?.type === 5) {
      try {
        const res = await Axios.get(`${ApiConfig.leaveApplication}&page=${page}&limit=${limit}`);
        if (res.status == 200) {
          setLeaveData(res?.data?.data);
          setCount(res.data.count)
        }
      } catch (error) { }
    };
  }
  useMemo(() => handleLeaveData(), [page])

  const [changeStatus, setChangeStatus] = useState("");

  const handleChangeLeaveStatus = async (e) => {
    e.preventDefault();
    try {
      const res = await Axios.post(ApiConfig.changeLeaveStatus, {
        leave_id: drawerData?.leave_id,
        leave_status: changeStatus,
        employee_id: drawerData?.employee_id,
      });
      if (res.status === 200) {
        handleLeaveData();
        setStatusDrawerOpen(false);
        toast.success("Status change successfully");
      } else {
        // console.log(res)
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message);
    }
  };
  const [leaveType, setLeaveType] = useState([]);
  const getLeaveTypes = async () => {
    await Axios.get(ApiConfig.listLeaveTypes).then((res) => {
      if (res.data.code === "200") {
        setLeaveType(res.data.data)
      }
    }).catch((err) => {
      console.log(err)
    })
  }
  useEffect(() => {
    getLeaveTypes();
  }, []);

  const initialState = {
    leave_types_id: "",
    leave_day_type: "",
    leave_date_start: currentDate,
    leave_date_end: currentDate,
    leave_reason: ""
  };

  const AddLeave = async (values) => {
    await Axios.post(ApiConfig.AddLeaveApplication, values, {
      headers: {
        Authorization: "Bearer " + token,
      },
    }).then((res) => {
      // console.log(res)
      if (res.data.code === "200") {
        toast.success(res.data.message)
        handleLeaveData()
        setIsDrawerOpen()
      } else {
        toast.error(res.data.message)
      }
    }).catch((err) => {
      console.log(err)
    })
  }
  const [emp_leave, setEmp_Leave] = useState([]);
  const handleEmployeeLeave = async () => {
    if (loginData?.id && loginData?.type !== 5) {
      try {
        const res = await Axios.post(ApiConfig.leaveApplicationListbyUser, {
          employee_id: loginData?.id,
        }, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        });
        // console.log(res);
        if (res.status === 200) {
          setLeaveData(res?.data?.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
  }
  useEffect(() => {
    handleEmployeeLeave();
  }, []);
  return (
    <div>
      <Dashboard>
        <PageHead title="Attendify || Leave Management" />
        <div className="page">
          <div className="page-wrapper" style={{ padding: "0px 10px" }}>
            <div className="container-lg container-main">
              <div className="page-header d-print-none mb-3">
                <div
                  style={{
                    display: "flex",
                    flexwrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h2 className="">Leave Management</h2>
                  {loginData?.type != 5 ? (
                    <div
                      className="btn btn-primary"
                      onClick={() => {
                        setIsDrawerOpen(true);
                        setDrawerHead("Add");
                      }}
                    >
                      Apply for Leave
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="card container-lg container-main">
                <div className="table-responsive">
                  <table className="table table-vcenter table-mobile-md card-table">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Emp_No</th>
                        <th>Name</th>
                        <th>Leave Days</th>
                        <th>Days Type</th>
                        <th>Status</th>
                        <th>Leave Type</th>
                        <th>Leave From</th>
                        <th>Leave To</th>
                        <th >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {leaveData &&
                        leaveData?.filter((val) => loginData?.type == 5 || val?.employee_id === loginData?.id).map((values, i) => {
                          return (
                            // (loginData?.type === 5 || values?.employee_id === loginData?.id) &&
                            <tr key={i}>
                              <td className="text" data-label="Role">
                                {i + 1}
                              </td>
                              <td className="text" data-label="Role">
                                {values.employee_no}
                              </td>

                              <td className="text" data-label="Role">
                                {values.employee_name}
                              </td>
                              <td className="text" data-label="Role">
                                {values.leave_days}
                              </td>
                              <td className="text" data-label="Role">
                                {values.leave_day_type == 1 ? "Whole Day" : values.leave_day_type == 2 ? "Half Day" : null}
                              </td>
                              <td className="text" data-label="Role">
                                <span
                                  className={
                                    values.leave_status === 0
                                      ? "text-color-blue"
                                      : values.leave_status === 1
                                        ? "text-color-green"
                                        : values.leave_status === 2
                                          ? "text-color-red"
                                          : values.leave_status === 3
                                            ? "text-color-orange"
                                            : null
                                  }
                                >
                                  {values.leave_status === 0 ? "Pending" : values.leave_status === 1 ? "Approved" : values.leave_status === 2 ? "Cancelled" : values.leave_status === 3 ? "Deny" : null}
                                </span>
                              </td>
                              <td className="text" data-label="Role">
                                {values.leave_type_code}
                              </td>
                              <td className="text" data-label="Role">
                                {/* {values.leave_date_start} */}
                                {moment(values.leave_date_start).format("DD-MM-YYYY")}
                              </td>
                              <td className="text" data-label="Role">
                                {values.leave_date_end === null ? "Half Day" : moment(values.leave_date_end).format("DD-MM-YYYY")}
                              </td>
                              <td><i onClick={() => {
                                setChangeStatus(values?.leave_status);
                                setStatusDrawerOpen(true);
                                setDrawerData(values);
                              }} className="fas fa-eye "></i></td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-end">
                  {leaveData?.length !== 0 && <Pagination count={Math.ceil(count / limit)} page={page} color="primary" onChange={(e, value) => setPage(value)} />}
                </div>
              </div>
            </div>

            <Drawer anchor="right" className="modal-blur" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
              <Box width="50rem">
                <div className="modal-dialog modal-lg" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">{drawerHead} Leave Application</h5>
                    </div>
                    <div className="modal-body bg-white">
                      <Formik
                        initialValues={initialState}
                        initialTouched={{
                          field: true,
                        }}
                        validationSchema={addLeave}
                        enableReinitialize
                        onSubmit={(values) => AddLeave(values)}
                      >
                        {({ errors, handleBlur, handleChange, touched, values }) => {
                          // console.log(errors);
                          return (
                            <Form>
                              <div className="form-selectgroup-boxes mb-3">
                                <div className="row">
                                  <div className="col-lg-6 mb-2">
                                    <label className="form-label">Leave Day Type</label>
                                    {drawerHead === "View" ? (
                                      <input type="text" className="form-control" readOnly={drawerHead === "View" ? true : false} placeholder="Employee Leave Type" />
                                    ) : (
                                      <Field as="select" className="form-select" name="leave_day_type" onChange={handleChange} onBlur={handleBlur} value={values?.leave_day_type}>
                                        <option value="">Select</option>
                                        <option value="1">Whole Day</option>
                                        <option value="2">Half Day</option>
                                      </Field>
                                    )}
                                    {errors?.leave_day_type && touched.leave_day_type ? <p className="form-valid">{errors?.leave_day_type}</p> : null}
                                  </div>
                                  <div className="col-lg-6 mb-2">
                                    <label className="form-label">Employee Leave Type</label>
                                    {drawerHead === "View" ? (
                                      <Field type="text" className="form-control" name="leave_types_id" readOnly={drawerHead === "View" ? true : false} placeholder="Employee Leave Type" />
                                    ) : (
                                      <Field as="select" className="form-select" onChange={handleChange} onBlur={handleBlur} value={values?.leave_types_id} name="leave_types_id">
                                        <option value="">Select</option>
                                        {
                                          leaveType?.map((val, i) => {
                                            return (
                                              <option key={i} value={val?.leave_type_id}>{val?.leave_type_name}</option>
                                            )
                                          })
                                        }
                                      </Field>
                                    )}
                                    {errors?.leave_types_id && touched.leave_types_id ? <p className="form-valid">{errors?.leave_types_id}</p> : null}
                                  </div>
                                  <div className="col-lg-6 mb-2">
                                    <label className="form-label">Leave From</label>
                                    <Field type="date" className="form-control" min={currentDate} name="leave_date_start" onChange={handleChange} onBlur={handleBlur} readOnly={drawerHead === "View" ? true : false} values={values?.leave_date_start} />
                                    {errors?.leave_date_start && touched.leave_date_start ? <p className="form-valid">{errors?.leave_date_start}</p> : null}
                                  </div>

                                  <div className="col-lg-6 mb-2">
                                    <label className="form-label">Leave To</label>
                                    <Field type="date" className="form-control" min={currentDate} name="leave_date_end" onChange={handleChange} onBlur={handleBlur} readOnly={drawerHead === "View" ? true : false} values={values?.leave_date_end} />
                                    {errors?.leave_date_end && touched.leave_date_end ? <p className="form-valid">{errors?.leave_date_end}</p> : null}
                                  </div>
                                  <div className="col-lg-6 mb-3">
                                    <div>
                                      <label className="form-label">Employee Reason</label>
                                      <textarea className="form-control" onChange={handleChange} onBlur={handleBlur} readOnly={drawerHead === "View" ? true : false} rows="3" name="leave_reason"></textarea>
                                    </div>
                                    {errors?.leave_reason && touched.leave_reason ? <p className="form-valid">{errors?.leave_reason}</p> : null}
                                  </div>
                                </div>
                              </div>
                              <div className="" align="right">
                                <button
                                  className="btn btn-info ms-auto"
                                  style={{ marginRight: "10px" }}
                                  onClick={() => {
                                    setIsDrawerOpen(false);
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-primary ms-auto"
                                  style={{ marginRight: "10px" }}
                                >
                                  Submit
                                </button>
                                {drawerHead == "View" ? (
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setDrawerHead("Edit");
                                    }}
                                    className="btn btn-primary ms-auto"
                                  >
                                    Edit
                                  </button>
                                ) : null}
                              </div>
                            </Form>
                          )
                        }
                        }
                      </Formik>
                    </div>
                  </div>
                </div>
              </Box>
            </Drawer>
            <Drawer anchor="right" className="modal-blur" open={statusDrawerOpen} onClose={() => setStatusDrawerOpen(false)}>
              <form>
                <Box width="50rem">
                  <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">Leave Application Status</h5>
                      </div>
                      <div className="modal-body bg-white">
                        <div className="">
                          <div className="form-selectgroup-boxes mb-3">
                            <div className="row">
                              <div className="col-lg-6 mb-2">
                                <label className="form-label">Employee ID</label>
                                <input type="text" value={`${drawerData?.employee_name} (${drawerData?.employee_no})`} readOnly className="form-control" />
                              </div>
                              <div className="col-lg-6 mb-2">
                                <label className="form-label">Leave Type</label>
                                <input type="text" value={drawerData?.leave_type_name} readOnly className="form-control" />
                              </div>
                              <div className="col-lg-12 mb-3">
                                <div>
                                  <label className="form-label">Employee Reason</label>
                                  <textarea className="form-control" value={drawerData?.leave_reason} rows="3" readOnly name="employee_office_address"></textarea>
                                </div>
                              </div>
                              <div className="col-lg-6 mb-2">
                                <label className="form-label">Employee Leave Status</label>
                                {drawerData?.leave_status !== 1 ? <select
                                  className="form-select"
                                  onChange={(e) => {
                                    setChangeStatus(e.target.value);
                                  }}
                                  defaultValue={changeStatus}

                                  name="leave_status"
                                >
                                  <option value="">---Choose Leave Status---</option>
                                  <option value="0">Pending</option>
                                  <option value="1">Approved</option>
                                  <option value="2">Cancelled</option>
                                  <option value="3">Deny</option>
                                </select> : <h3 className="text-success">Approve</h3>}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div align="right">
                          <button type="button" className="btn btn-info ms-auto mx-2" onClick={() => setStatusDrawerOpen(false)}>
                            Cancel
                          </button>
                          {
                            loginData?.type === 5 &&
                            <button onClick={handleChangeLeaveStatus} className="btn btn-primary ms-auto" data-bs-dismiss="modal">
                              Submit
                            </button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </form>
            </Drawer>
          </div>
        </div>
      </Dashboard >
    </div >
  );
};

export default LeaveMgmt;
