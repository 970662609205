// const url = "http://192.168.101.18:3004/api/v1";
// const url = "http://localhost:3007/api/v1";
const url = "https://attendify-api.redesk.in/api/v1";

const ApiConfig = {
  login: `${url}/login?_format=json`,
  departmentList: `${url}/getDepartment?_format=json`,
  updatePassword: `${url}/updatedPassord?_format=json`,
  sendCandidateStatus: `${url}/updateCandidateStatus?_format=json`,
  sendCandidateResume: `${url}/uploadFile?_format=json`,
  employeeEditDetails: `${url}/editemployee?_format=json`,
  viewResume: `${url}/getFileDetails?_format=json`,
  leaveApplication: `${url}/leaveApplicationList?_format=json`,
  addLeaveType: `${url}/addLeaveTypedetails?_format=json`,
  editLeaveType: `${url}/editLeaveTypes?_format=json`,
  changeLeaveStatus: `${url}/changeLeaveStatus?_format=json`,
  leaveApplicationListbyUser: `${url}/leaveApplicationListbyUser?_format=json`,
  empLeaveMap: `${url}/employeeLeaveMap?_format=json`,
  empLeaveTypeMap: `${url}/employeeWithLeaveType?_format=json`,
  openResumeNewTab: `${url}/stream`,
  designationList: `${url}/getDesignation?_format=json`,
  addCandidate: `${url}/addCandidate?_format=json`,
  editCandidate: `${url}/updateCandidateDetails?_format=json`,
  getCandidateDetails: `${url}/getCandidateDetails?_format=json`,
  getEmployeeT: `${url}/getEmployee?_format=json`,
  getIntern: `${url}/getEmployee?_format=json`,
  getDesignationT: `${url}/getDesignation?_format=json`,
  addEmployee: `${url}/addEmployee?_format=json`,
  sendOfferLetter: `${url}/sendOfferLetter?_format=json`,
  getAttendanceByEmployee: `${url}/getAttendanceByEmployee?_format=json`,
  updateCandidateCallStatus: `${url}/updateCandidateCallStatus?_format=json`,
  AddLeaveApplication: `${url}/AddLeaveApplication?_format=json`,
  listLeaveTypes: `${url}/listLeaveTypes?_format=json`,
  changeLeaveTypeStatus: `${url}/changeLeaveTypeStatus?_format=json`,
  getExperience: `${url}/getExperience?_format=json`,
  addExperience: `${url}/addExperienceList?_format=json`,
  updateExperience: `${url}/updateExperience?_format=json`,
  getEducation: `${url}/getEducation?_format=json`,
  addEducation: `${url}/addEducation?_format=json`,
  updateEducation: `${url}/updateEducation?_format=json`,
  getFileByUser: `${url}/getFileByUser?_format=json`,
  upload: `${url}/upload?_format=json`,
  getFileTypes: `${url}/getFileTypes?_format=json`,
  addFileType: `${url}/addFileType?_format=json`,
  //company management
  getCompanies: `${url}/getCompanies?_format=json`,
  getCompanyDetails: `${url}/getCompanyDetails?_format=json`,
  getBranches: `${url}/getBranches?_format=json`,
  getEmployeesByBranch: `${url}/getEmployeesByBranch?_format=json`,
  addCompany: `${url}/addCompany?_format=json`,
  updateCompany: `${url}/updateCompany?_format=json`,
  addBranch: `${url}/addBranch?_format=json`,
  updateBranch: `${url}/updateBranch?_format=json`,
};
export default ApiConfig;
