import styled from "styled-components";

export const Button = styled.div`
  position: fixed;
  right: 8px;
  bottom: 8px;
  font-size: 30px;
  z-index: 1;
  cursor: pointer;
  color: #206bc4;
`;
