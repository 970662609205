import React, { useState, useEffect } from "react";
import Dashboard from "./Dashboard";
import { Link } from "react-router-dom";
import { Drawer, Box } from "@mui/material";
import Axios from "axios";
import { toast } from "react-toastify";
import ApiConfig from "../Config/APIConfig";
import ButtonCircleLoader from "../CircleLoading/ButtonCircleLoader";
import moment from "moment";
const Updatepassword = () => {
  const [oldPassword, setOldPassword] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [empID, setEmpID] = useState("");
  const [checkPassword, setCheckPassword] = useState(true);
  const [password, setPassword] = useState("");

  useEffect(() => {
    let Data = localStorage.getItem("Employee_Details");
    setEmpID(JSON.parse(Data));
  }, []);
  const [inputData, setInputData] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });
  const handleInsert = (e) => {
    const { name, value } = e.target;

    setInputData({
      ...inputData,
      [name]: value,
    });
  };
  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    try {
      const res = await Axios.post(ApiConfig.updatePassword);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckPassword = (e) => {
    e.preventDefault();
    if (inputData?.new_password === inputData?.confirm_password) {
      // console.log("matched");
      setCheckPassword(true);
      handleUpdatePassword();
    } else {
      setCheckPassword(false);
      // console.log("not matched");
    }
  };

  //   useEffect(() => {
  //   if (inputData?.new_password == inputData?.confirm_password) {
  //     console.log("matched");
  //   } else {
  //     setCheckPassword(false);
  //     console.log("not matched");
  //   }
  //   }, [inputData?.confirm_password]);

  return (
    <div>
      <Dashboard>
        <div className="page">
          <div className="page-wrapper" style={{ padding: "0px 10px" }}>
            <div className="container page-header" style={{ margin: "auto" }}>
              <form className="card card-md" autoComplete="off">
                <div className="card-body">
                  <h2 className="card-title text-center mb-4">
                    Update Password
                  </h2>
                  <div className="mb-2">
                    <label className="form-label">Employee ID</label>
                    <input
                      type="text"
                      value={empID?.employee_no}
                      readOnly
                      name="emp_id"
                      //   onChange={handleInsert}
                      className="form-control"
                      placeholder="Enter Employee ID"
                      autoComplete="off"
                    />
                  </div>
                  <div className="mb-2">
                    <label className="form-label">Old Password</label>
                    <div className="input-group input-group-flat">
                      <input
                        type={oldPassword ? "text" : "password"}
                        className="form-control"
                        name="old_password"
                        value={inputData?.old_password}
                        onChange={handleInsert}
                        placeholder="Enter Old Password"
                        autoComplete="off"
                      />
                      <span className="input-group-text">
                        <a
                          onClick={() => {
                            setOldPassword(!oldPassword);
                          }}
                          className="link-secondary"
                          title="Show password"
                          data-bs-toggle="tooltip"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <circle cx="12" cy="12" r="2" />
                            <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                          </svg>
                        </a>
                      </span>
                    </div>
                  </div>
                  <div className="mb-2">
                    <label className="form-label">New Password</label>
                    <div className="input-group input-group-flat">
                      <input
                        type={newPassword ? "text" : "password"}
                        className="form-control"
                        name="new_password"
                        value={inputData?.new_password}
                        onChange={handleInsert}
                        placeholder="Enter New Password"
                        autoComplete="off"
                      />
                      <span className="input-group-text">
                        <a
                          onClick={() => {
                            setNewPassword(!newPassword);
                          }}
                          className="link-secondary"
                          title="Show password"
                          data-bs-toggle="tooltip"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <circle cx="12" cy="12" r="2" />
                            <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                          </svg>
                        </a>
                      </span>
                    </div>
                  </div>
                  <div className="mb-2">
                    <label className="form-label">Confirm Password</label>
                    <div className="input-group input-group-flat">
                      <input
                        type={confirmPassword ? "text" : "password"}
                        className="form-control"
                        name="confirm_password"
                        value={inputData?.confirm_password}
                        onChange={handleInsert}
                        placeholder="Enter New Password"
                        autoComplete="off"
                      />
                      <span className="input-group-text">
                        <a
                          onClick={() => {
                            setConfirmPassword(!confirmPassword);
                          }}
                          className="link-secondary"
                          title="Show password"
                          data-bs-toggle="tooltip"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <circle cx="12" cy="12" r="2" />
                            <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                          </svg>
                        </a>
                      </span>
                    </div>
                  </div>
                  <div className="mb-2">
                    <p>
                      {inputData?.new_password === inputData?.confirm_password
                        ? () => {
                          setCheckPassword(true);
                        }
                        : () => {
                          setCheckPassword(false);
                        }}
                    </p>
                  </div>
                  <div className="form-footer">
                    <button
                      disabled={checkPassword}
                      //   onClick={handleUpdatePassword}
                      className="btn btn-primary w-100"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Dashboard>
    </div>
  );
};

export default Updatepassword;
