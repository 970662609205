import React from "react";
import { useNavigate } from "react-router-dom";
const PageError = () => {
  const navigate = useNavigate();
  return (
    <div className="errror_page">
      <div className="error_image_box">
        <img src="./images/error-page.jpg" alt="Error_image" />
        <button
          className="btn btn-primary"
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          Back To Dashboard
        </button>
      </div>
    </div>
  );
};

export default PageError;
