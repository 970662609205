import { useState, useEffect, useMemo } from 'react'
import Dashboard from '../Dashboard'
import PageHead from '../PageHead'
import { Drawer, Box } from '@mui/material'
import { Formik, Form, Field } from "formik"
import axios from 'axios'
import ApiConfig from '../../Config/APIConfig'
import { addIntern } from '../schema'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
export default function Internship() {
    const [data, setData] = useState("");
    const [open, setOpen] = useState(false);
    const [token, setToken] = useState("")
    useEffect(() => {
        setToken(JSON.parse(localStorage.getItem("Att_token")));
    }, []);
    const [internList, setInternList] = useState([]);
    const [internByCompany, setInternByCompany] = useState(1)
    const [internByBranch, setInternByBranch] = useState("")
    const getInternDetails = async () => {
        await axios.get(`${ApiConfig.getIntern}&employee_company=${internByCompany}&employee_type=4`, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then((res) => {
            // console.log(res)
            setInternList(res.data.data);
        })
    }
    useEffect(() => {
        getInternDetails();
    }, [internByCompany, internByBranch]);
    const getEmpByBranch = async () => {
        await axios.get(`${ApiConfig.getEmployeesByBranch}&branch_id=${internByBranch}`, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then((res) => {
            // console.log(res)
            if (res.status === 200) {
                setInternList(res?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        })
    }
    useEffect(() => {
        if (internByBranch) getEmpByBranch();
    }, [internByBranch]);
    const [companyList, setCompanyList] = useState([]);
    const getCompany = async () => {
        if (token) {
            await axios.get(ApiConfig.getCompanies, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }).then((res) => {
                res?.data?.code === "200" ? setCompanyList(res?.data?.data) : setCompanyList([])
            }).catch((err) => {
                throw err;
            })
        }
    }
    useEffect(() => {
        getCompany();
    }, [token]);
    const [getBranch, setGetBranch] = useState([])
    const getBranchDetails = async (value) => {
        if ((token && value) || (token && data?.employee_company)) {
            await axios.get(`${ApiConfig.getBranches}&company_id=${value === undefined ? data?.employee_company : value}`, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }).then((res) => {
                // console.log(res)
                res?.data?.code === "200" ? setGetBranch(res?.data?.data) : setGetBranch([])
            }).catch((err) => {
                throw new Error(err)
            })
        }
    }
    useEffect(() => {
        getBranchDetails();
    }, [token, data?.employee_company]);
    // Designation List
    const [getDesg, setGetDesg] = useState([]);
    const getDesignation = async () => {
        try {
            const res = await axios.post(ApiConfig.getDesignationT, {
                // designation_status: employee_company,
                designation_status: 1,
                pagination: true,
                limit: 10,
            });
            if (res.status == 200) {
                // console.log("asdasfgdfshd", res);
                setGetDesg(res?.data?.data);
            }
        } catch (error) {
            throw new Error(error);
        }
    };

    useEffect(() => {
        getDesignation();
    }, []);
    const [departList, setDeprtList] = useState();
    const DepartmentList = async () => {
        try {
            const res = await axios.post(ApiConfig.departmentList, {
                // department_status: employee_company,
                department_status: 1,
                pagination: true,
                limit: 10,
            });
            if (res.status === 200) {
                // console.log("sdfsgfsd", res);
                setDeprtList(res.data.data);
            }
        } catch (error) {
            // console.log(error);
            throw new Error(error)
        }
    };
    useEffect(() => {
        DepartmentList();
    }, []);
    const initialValue = {
        employee_name: data?.employee_name || "",
        employee_email: data?.employee_email || "",
        employee_gender: data?.employee_gender || "",
        employee_dob: data?.employee_dob || "",
        employee_service_email: data?.employee_service_email || "",
        employee_mobile: data?.employee_mobile || "",
        employee_designation: data?.employee_designation || "",
        employee_company: data?.employee_company || "",
        employee_branch_type: data?.employee_branch_type || "",
        employee_home_address: data?.employee_home_address || "",
        employee_office_address: data?.employee_office_address || "",
        employee_present_address: data?.employee_present_address || "",
        employee_type: 4 || "",
        employee_password: data?.employee_password || "",
    }
    const [openDrawer, setOpenDrawer] = useState(false);
    const [drawerHead, setDrawerHead] = useState("");
    useMemo(() => !openDrawer ? setData("") : null, [openDrawer])
    const [addInternLoader, setAddInternLoader] = useState(false);
    const AddIntern = async (val) => {
        setAddInternLoader(true);
        await axios.post(ApiConfig.addEmployee, { ...val, "employee_company": parseInt(val?.employee_company) }, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then((res) => {
            // console.log(res)
            if (res.data.code === "200") {
                setAddInternLoader();
                getInternDetails();
                toast.success("Intern added successfully")
                setOpenDrawer();
            }
        }).catch((error) => {
            setAddInternLoader()
            if (error.response.data.code === "404") {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.response.data.message);
            }
        })
    }
    const EditIntern = async (val) => {
        setAddInternLoader(true)
        await axios.post(ApiConfig.employeeEditDetails, { "employee_id": data?.employee_id, ...val, "employee_company": parseInt(val?.employee_company) }, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then((res) => {
            // console.log(res)
            if (res.data.code === "200") {
                setAddInternLoader();
                getInternDetails();
                toast.success("Intern Update successfully")
                setOpenDrawer();
            }
        }).catch((err) => {
            setAddInternLoader();
            toast.error(err.response.data.message)
        })
    }
    return (
        <Dashboard>
            <PageHead title="Attendify || Candidate" />
            <div className="page">
                <div className="page-wrapper" style={{ padding: "0px 10px" }}>
                    <div className="container-lg container-main">
                        <div className="page-header d-print-none mb-3">
                            <div
                                style={{
                                    display: "flex",
                                    flexwrap: "wrap",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        flexDirection: "column",
                                        marginRight: "15px",
                                    }}
                                >
                                    <h2>Internship Candidate List</h2>
                                </div>
                                <div className="d-flex">
                                    <select className="form-select mx-4" onChange={(e) => { setInternByCompany(e.target.value); getBranchDetails(e.target.value) }} defaultValue={internByCompany}>
                                        <option value="">Choose Branch</option>
                                        {companyList?.map((val, i) => {
                                            return (
                                                <option key={i} value={val?.company_id}>
                                                    {val?.company_name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <select className="form-select" onChange={(e) => setInternByBranch(e.target.value)} value={internByBranch}>
                                        <option value="">Choose Branch</option>
                                        {getBranch?.map((val, i) => {
                                            return (
                                                <option key={i} value={val?.branch_id}>
                                                    {val?.branch_name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div className="d-print-none">
                                    <div className="btn-list">
                                        <button
                                            className="btn btn-primary d-none d-sm-inline-block"
                                            onClick={() => { setOpenDrawer(true); setDrawerHead("Add") }}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="icon"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                strokeWidth="2"
                                                stroke="currentColor"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <line x1="12" y1="5" x2="12" y2="19" />
                                                <line x1="5" y1="12" x2="19" y2="12" />
                                            </svg>
                                            Add Intern Candidate
                                        </button>
                                        <button className="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal" data-bs-target="#modal-report" aria-label="Create new report">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="icon"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                strokeWidth="2"
                                                stroke="currentColor"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <line x1="12" y1="5" x2="12" y2="19" />
                                                <line x1="5" y1="12" x2="19" y2="12" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card container-lg container-main">
                        <div className="table-responsive">
                            {/* IN TABLE TAG REMOVE ......table-mobile-md..... */}
                            <table className="table table-vcenter card-table">
                                <thead>
                                    <tr>
                                        <th>Intern no</th>
                                        <th>Intern name</th>
                                        <th>Intern mobile</th>
                                        <th>Intern service email</th>
                                        <th>Intern gender</th>
                                        <th>Intern dob</th>
                                        <th>Company Name</th>
                                        <th>department name</th>
                                        <th>designation name</th>
                                        <th className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        internList.map((item, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td data-label="Name">
                                                        <div className="d-flex py-1 align-items-center">
                                                            <div className="flex-fill">
                                                                <div className="font-weight-medium">{item.employee_no}</div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td data-label="Title">{item.employee_name}</td>
                                                    <td className="text" data-label="Role">
                                                        {item.employee_mobile}
                                                    </td>
                                                    <td className="text" data-label="Role">
                                                        {item.employee_service_email}
                                                    </td>
                                                    <td className="text" data-label="Role">
                                                        {item.employee_gender === 1 ? "Male" : "Female"}
                                                    </td>
                                                    <td className="text" data-label="Role">
                                                        {item.employee_dob}
                                                    </td>
                                                    <td className="text" data-label="Role">
                                                        {item.employee_branch_type === 1 ? "Recharge Kit" : null || item.employee_branch_type === 2 ? "Refab" : null || item.employee_branch_type === 3 ? "Renxt" : null}
                                                    </td>
                                                    <td className="text" data-label="Role">
                                                        {item.department_name}
                                                    </td>
                                                    <td className="text" data-label="Role">
                                                        {item.designation_name}
                                                    </td>
                                                    <td>
                                                        <div className="btn-list flex-nowrap">
                                                            <span onClick={() => {
                                                                setOpenDrawer(true);
                                                                setData(item);
                                                                setDrawerHead("Edit");
                                                            }} className="btn">
                                                                Edit
                                                            </span>
                                                            {/* <Link to="/emp_view" state={item} className="btn">
                                                                View
                                                            </Link> */}
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="card-footer d-flex align-items-center">
                            <p className="m-0 text-muted">
                                Showing <span>1</span> to <span>8</span> of <span>16</span> entries
                            </p>
                        </div>
                    </div>
                </div>
                <Drawer anchor='right' open={openDrawer} className='modal-blur' onClose={() => setOpenDrawer()}>
                    <Box width="40rem">
                        <Formik
                            initialValues={initialValue}
                            initialTouched={{
                                field: true,
                            }}
                            validationSchema={addIntern}
                            enableReinitialize
                            onSubmit={(values) => {
                                if (drawerHead === "Add") {
                                    AddIntern(values)
                                } else {
                                    EditIntern(values)
                                }
                            }}
                        >
                            {({ errors, handleBlur, handleChange, touched, values }) => {
                                // console.log(values)
                                return (
                                    <Form>
                                        <div className="modal-body">
                                            <div className="form-selectgroup-boxes row">
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">CHOOSE COMPANY</label>
                                                        <Field as="select" className="form-select" name="employee_company" onChange={(e) => { handleChange(e); getBranchDetails(e.target.value) }} onBlur={handleBlur} value={values?.employee_company}>
                                                            <option value="">Select</option>
                                                            {
                                                                companyList?.map((val, i) => {
                                                                    return (
                                                                        <option key={i} value={val?.company_id}>{val?.company_name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </Field>
                                                        {errors?.employee_company && touched.employee_company ? <p className="form-valid">{errors?.employee_company}</p> : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">CHOOSE BRANCH</label>
                                                        <Field as="select" className="form-select" name="employee_branch_type" onChange={handleChange} onBlur={handleBlur} value={values?.employee_branch_type}>
                                                            <option value="" selected>Select</option>
                                                            {
                                                                getBranch?.map((val, i) => {
                                                                    return (
                                                                        <option key={i} value={val?.branch_id}>{val?.branch_name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </Field>
                                                        {errors?.employee_branch_type && touched.employee_branch_type ? <p className="form-valid">{errors?.employee_branch_type}</p> : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <label className="form-label">INTERN NAME</label>
                                                    <Field type="text" className="form-control" name="employee_name" placeholder="employee_name" onChange={handleChange} onBlur={handleBlur} value={values?.employee_name} />
                                                    {errors?.employee_name && touched.employee_name ? <p className="form-valid">{errors?.employee_name}</p> : null}
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">INTERN SERVICE EMAIL</label>
                                                        <div className="input-group input-group-flat">
                                                            <Field type="email" className="form-control" name="employee_service_email" placeholder="employee_service_emaill" onChange={handleChange} onBlur={handleBlur} value={values?.employee_service_email} />
                                                        </div>
                                                        {errors?.employee_service_email && touched.employee_service_email ? <p className="form-valid">{errors?.employee_service_email}</p> : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-selectgroup-boxes row mb-3">
                                                <div className="col-lg-6 mb-3">
                                                    <label className="form-label">INTERN MOBILE</label>
                                                    <Field type="number" className="form-control" name="employee_mobile" placeholder="employee_mobile" onChange={handleChange} onBlur={handleBlur} value={values?.employee_mobile} />
                                                    {errors?.employee_mobile && touched.employee_mobile ? <p className="form-valid">{errors?.employee_mobile}</p> : null}
                                                </div>

                                                <div className="col-lg-6">
                                                    <label className="form-label">INTERN EMAIL</label>
                                                    <Field type="email" className="form-control" name="employee_email" placeholder="employee_email" onChange={handleChange} onBlur={handleBlur} value={values?.employee_email} />
                                                    {errors?.employee_email && touched.employee_email ? <p className="form-valid">{errors?.employee_email}</p> : null}
                                                </div>
                                            </div>
                                            <div className="row">
                                                {
                                                    drawerHead !== "Edit" &&
                                                    <div className="col-lg-6">
                                                        <div className="mb-3">
                                                            <label className="form-label">INTERN PASSWORD</label>
                                                            <div className="input-group input-group-flat">
                                                                <Field type="password" className="form-control" name="employee_password" placeholder="employee_password" onChange={handleChange} onBlur={handleBlur} value={values?.employee_password} />
                                                            </div>
                                                            {errors?.employee_password && touched.employee_password ? <p className="form-valid">{errors?.employee_password}</p> : null}
                                                        </div>
                                                    </div>
                                                }
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">INTERN GENDER</label>
                                                        <Field as="select" className="form-select" name="employee_gender" onChange={handleChange} onBlur={handleBlur} value={values?.employee_gender}>
                                                            <option value="">Select</option>
                                                            <option value="1">Male</option>
                                                            <option value="2">Female</option>
                                                            {/* <option value="3">Hidden</option> */}
                                                        </Field>
                                                        {errors?.employee_gender && touched.employee_gender ? <p className="form-valid">{errors?.employee_gender}</p> : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="modal-body">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">INTERN DEPARTMENT</label>
                                                        <Field as="select" className="form-select" disabled name="employee_type" onChange={handleChange} onBlur={handleBlur} value={values?.employee_type}>
                                                            <option value="">Select</option>
                                                            {departList &&
                                                                departList.map((item, i) => {
                                                                    return (
                                                                        <option key={i} value={item.department_id}>
                                                                            {item.department_name}
                                                                        </option>
                                                                    );
                                                                })}

                                                        </Field>
                                                        {errors?.employee_type && touched.employee_type ? <p className="form-valid">{errors?.employee_type}</p> : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">INTERN DESIGNATION</label>
                                                        <Field as="select" className="form-select" name="employee_designation" onChange={handleChange} onBlur={handleBlur} value={values?.employee_designation}>
                                                            <option value="" selected>Select</option>
                                                            {getDesg
                                                                ?.filter((val) => val?.department_id === 1)
                                                                .map((item, i) => {
                                                                    // console.log(values?.employee_type)
                                                                    return (
                                                                        <option key={i} value={item?.designation_id}>
                                                                            {item?.designation_name}
                                                                        </option>
                                                                    );
                                                                })}
                                                        </Field>
                                                        {errors?.employee_designation && touched.employee_designation ? <p className="form-valid">{errors?.employee_designation}</p> : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">INTERN DOB</label>
                                                        <Field type="date" className="form-control" name="employee_dob" onChange={handleChange} onBlur={handleBlur} value={values?.employee_dob} />
                                                        {errors?.employee_dob && touched.employee_dob ? <p className="form-valid">{errors?.employee_dob}</p> : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <div>
                                                        <label className="form-label">Intern Home Address</label>
                                                        <textarea className="form-control" rows="3" name="employee_home_address" onChange={handleChange} onBlur={handleBlur} value={values?.employee_home_address}></textarea>
                                                        {errors?.employee_home_address && touched.employee_home_address ? <p className="form-valid">{errors?.employee_home_address}</p> : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <div>
                                                        <label className="form-label">Intern Office Address</label>
                                                        <textarea className="form-control" rows="3" name="employee_office_address" onChange={handleChange} onBlur={handleBlur} value={values?.employee_office_address}></textarea>
                                                        {errors?.employee_office_address && touched.employee_office_address ? <p className="form-valid">{errors?.employee_office_address}</p> : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb3">
                                                    <div>
                                                        <label className="form-label">Intern Present Address</label>
                                                        <textarea className="form-control" rows="3" name="employee_present_address" onChange={handleChange} onBlur={handleBlur} value={values?.employee_present_address}></textarea>
                                                        {errors?.employee_present_address && touched.employee_present_address ? <p className="form-valid">{errors?.employee_present_address}</p> : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button onClick={() => setOpenDrawer()} className="btn btn-link link-secondary">
                                                Cancel
                                            </button>
                                            <button
                                                className="btn btn-primary ms-auto"
                                                type="submit"
                                                disabled={addInternLoader}
                                            >
                                                {addInternLoader ? "Loading..." : "Submit"}
                                            </button>
                                        </div>
                                    </Form>
                                )
                            }
                            }
                        </Formik>
                    </Box>
                </Drawer>
            </div>
        </Dashboard>
    )
}
