import { useState, useEffect, useContext } from "react";
import ApiConfig from "../Config/APIConfig";
import DataContext from "../Context/Context";
import axios from "axios";
import Dashboard from "./Dashboard";
import PageHead from "./PageHead";
import { toast } from 'react-toastify'
import moment from 'moment';
import Pagination from '@mui/material/Pagination';
const Attendance = ({ user }) => {
  // console.log(user)
  const [token, setToken] = useState("")
  useEffect(() => {
    setToken(JSON.parse(localStorage.getItem("Att_token")))
  }, []);
  const ContextData = useContext(DataContext)
  const { loginData } = ContextData;
  // console.log(loginData)
  let currentDate = new Date().toJSON().slice(0, 10);
  let limit = 10;
  const [page, setPage] = useState(1)
  const [filterData, setFilterData] = useState({
    employee_id: "",
    employee_type: "",
    fromdate: currentDate,
    todate: currentDate,
    attendance_type: "",
  })
  const handleChange = (e) => {
    let { name, value } = e.target;
    setFilterData({
      ...filterData, [name]: value
    })
  }
  const [getEmpByAttd, setGetEmpByAttd] = useState([]);
  const [count, setCount] = useState("");
  const [counter, setCounter] = useState(0);
  const getEmpByAttendance = async (i) => {
    if (i == 1)
      setPage(1);
    let obj = { ...filterData }
    if (loginData?.type != 5) obj.employee_id = loginData?.id;
    await axios.post(ApiConfig.getAttendanceByEmployee, { ...obj, limit: limit, page: i == 1 ? 1 : page }).then((res) => {
      if (res.data.code === "200") {
        setCount(res.data.data.count)
        setGetEmpByAttd(res.data.message)
      } else {
        setGetEmpByAttd([])
      }
    }).catch((err) => {
      throw new Error(err.message)
    })
  }
  useEffect(() => {
    getEmpByAttendance(0);
  }, [page, counter]);
  const [departmentList, setDepartmentList] = useState([]);
  const getDepartment = async () => {
    await axios.post(ApiConfig.departmentList, {
      "department_status": "1",
      "pagination": "true",
      "limit": "10"
    }, {
      headers: {
        Authorization: "Bearer " + token
      }
    }).then((res) => {
      if (res.data.code === "200") {
        setDepartmentList(res.data.data)
      }
    }).catch((err) => {
      throw new Error(err)
    })
  }
  useEffect(() => {
    getDepartment();
  }, []);
  const handleReset = () => {
    setFilterData({
      employee_id: "",
      employee_type: "",
      fromdate: currentDate,
      todate: currentDate,
      attendance_type: "",
    })
    setCounter(c => c + 1)
  }
  return (
    <div>
      <Dashboard>
        <PageHead title="Attendify || Attendance" />
        <div className="col-12" style={{ padding: "0px 10px" }}>
          <div className="page-wrapper container-lg container-main">
            <div className="page-header d-print-none">
              <div
                style={{
                  display: "flex",
                  flexwrap: "wrap",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    marginRight: "15px",
                  }}
                >
                  <h2 className="">Employee Attendance</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header justify-content-between">
              <h3 className="card-title">Invoices</h3>
              <div>
                <a data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample" className="btn btn-primary">
                  Filter
                </a>
              </div>
            </div>
            <div className="collapse" id="collapseExample">
              <div className="container">
                <div className="row">
                  {loginData?.type == 5 &&
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="empId" className="form-label">Employee ID</label>
                        <input type="text" name="employee_id" className="form-control" id="empId" placeholder="Enter Employee Id" onChange={handleChange} value={filterData.employee_id} />
                      </div>
                    </div>
                  }

                  <div className="col-md-3">
                    <div className="mb-3">
                      <label htmlFor="attendance" className="form-label">Department Wise</label>
                      <select className="form-select" aria-label="Default select example" name="employee_type" onChange={handleChange} value={filterData.employee_type}>
                        <option value="" selected>Choose Attendance Type</option>
                        {
                          departmentList?.map((val) => {
                            return (
                              <option key={val?.department_id} value={val?.department_id}>{val?.department_name}</option>
                            )
                          })
                        }
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label htmlFor="attendance" className="form-label">Attendance Type</label>
                      <select className="form-select" aria-label="Default select example" name="attendance_type" onChange={handleChange} value={filterData.attendance_type}>
                        <option value="" selected>Choose Attendance Type</option>
                        <option value="1">Normal</option>
                        <option value="2">Lunch Break</option>
                        <option value="3">Other Break</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label htmlFor="fromdate" className="form-label">From Date</label>
                      <input type="date" className="form-control" id="fromdate" name="fromdate" onChange={handleChange} value={filterData.fromdate} />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label htmlFor="todate" className="form-label">To Date</label>
                      <input type="date" className="form-control" id="todate" name="todate" onChange={handleChange} value={filterData.todate} />
                    </div>
                  </div>
                </div>
                <div className=" d-flex justify-content-end">
                  <button onClick={handleReset} className="btn btn-secondary mx-2">
                    Reset
                  </button>
                  <input type="submit" value="Search" onClick={() => getEmpByAttendance(1)} className="btn btn-success" />
                </div>
              </div>
            </div>
            <div className="card-body border-bottom py-3">
              <div className="d-flex">
                <div className="text-muted">
                  Show
                  <div className="mx-2 d-inline-block">
                    <input type="text" className="form-control form-control-sm" value={page} size="3" aria-label="Invoices count" readOnly />
                  </div>
                  entries
                </div>
                {/* <div className="ms-auto text-muted">
                  Search:
                  <div className="ms-2 d-inline-block">
                    <input type="text" className="form-control form-control-sm" aria-label="Search invoice" />
                  </div>
                </div> */}
              </div>
            </div>
            <div className="table-responsive">
              <table className="table card-table table-vcenter text-nowrap datatable">
                <thead>
                  <tr>
                    <th>S no.</th>
                    <th>Emp No</th>
                    <th>Emp Name</th>
                    <th>Attd Id</th>
                    <th>Attd Type</th>
                    <th>Attd Start</th>
                    <th>Attd End</th>
                    <th>time DIff</th>
                    <th>Created By</th>
                  </tr>
                </thead>
                <tbody>

                  {
                    getEmpByAttd.length === 0 ? <p className="mx-2 my-2">No data Found</p> : getEmpByAttd?.map((val, i) => {

                      const attendanceStart = moment(val.attendance_start);
                      const attendanceEnd = moment(val.attendance_end);

                      const duration = moment.duration(attendanceEnd.diff(attendanceStart));

                      const hours = duration.hours();
                      const minutes = duration.minutes();
                      const seconds = duration.seconds();

                      // console.log(`The difference is ${hours} hours, ${minutes} minutes, and ${seconds} seconds.`);
                      return (
                        // loginData?.type === 5 || val?.employee_id === loginData?.id &&
                        <tr key={i}>
                          <td>{i + 1 + ((page - 1) * limit)}</td>
                          <td>{val?.employee_no}</td>
                          <td>{val.employee_name}</td>
                          <td>{val.attendance_id}</td>
                          <td>{val.attendance_type === 1 ? "Normal" : val.attendance_type === 2 ? "Lunch Break" : "Other Break"}</td>
                          <td>{moment.utc(val.attendance_start).format("DD-MM-YYYY")} < br /> {moment.utc(val.attendance_start).format("hh:mm:ss a")}</td>
                          <td>{val?.attendance_end !== null ? moment.utc(val?.attendance_end).format("DD-MM-YYYY") : "Not Available"} < br /> {val?.attendance_end !== null ? moment.utc(val?.attendance_end).format("hh:mm:ss a") : "Not Available"}</td>
                          <td>{`${hours} hours, ${minutes} minutes, and ${seconds} seconds`}</td>
                          <td>Self</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
            <div className="card-footer d-flex justify-content-between">
              <p className="m-0 text-muted">
                Showing <span>1</span> to <span>{count}</span> of <span>{limit}</span> entries
              </p>
              <div>
                {getEmpByAttd.length !== 0 && <Pagination count={Math.ceil(count / limit)} color="primary" page={page} onChange={(e, value) => setPage(value)} />}
              </div>
            </div>
          </div>
        </div>
      </Dashboard >
    </div >
  );
};

export default Attendance;
