import Axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ButtonCircleLoader from "../CircleLoading/ButtonCircleLoader";
import ApiConfig from "../Config/APIConfig";
const Login = () => {
  const navigate = useNavigate();
  const [loginID, setLoginID] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const res = await Axios.post(ApiConfig.login, {
        email: loginID,
        password: userPassword,
      });
      if (res.status === 200) {
        setIsLoading(false);
        localStorage.setItem("Att_token", res.data.token);
        localStorage.setItem("userName", res.data.user.name);
        toast.success(res.msg);
        navigate("/dashboard");
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Incorrect login credentials");
    }
  };

  return (
    <div className="page page-center">
      <div className="container-tight py-4">
        <div className="text-center mb-4">
          <a href="." className="navbar-brand navbar-brand-autodark ">
            <img className="logo" src="./static/abc_black.png" alt="" />
          </a>
        </div>
        <form className="card card-md" autoComplete="off">
          <div className="card-body">
            <h2 className="card-title text-center mb-4">Forget Password</h2>
            <div className="mb-3">
              <label className="form-label">Email address</label>
              <input
                type="email"
                className="form-control"
                placeholder="Enter email"
                onChange={(e) => {
                  setLoginID(e.target.value);
                }}
                autoComplete="off"
              />
            </div>

            <div className="form-footer">
              <button type="submit" className="btn btn-primary w-100">
                Sign in {isLoading && <ButtonCircleLoader />}
              </button>
            </div>
            <div align="center" className="mt-3">
              <span className="">
                <Link to="/login">Back To Login</Link>
              </span>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
