import * as yep from "yup"
import moment from "moment"
const MobileRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const name = /^[A-Za-z\s]{1,}[/.,]{0,1}[A-Za-z\s]{0,}$/
const email = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
export const addCandidate = yep.object({
    candidate_company: yep.string().required("Company Required"),
    candidate_mobile: yep.string().trim().min(10, "Mobile number should be 10 digit").max(10, "Mobile number should be 10 digit").matches(MobileRegExp, 'Mobile number is not valid').required("Mobile number required"),
    candidate_name: yep.string().trim().min(3, "Enter a valid name").max(30, "Name should not be longer than 30 characters").matches(name, 'Invalid Name').required("Name required"),
    candidate_email: yep.string().trim().matches(email, 'Enter a valid email address').required("Email required"),
    candidate_gender: yep.string().required("Gender required"),
    candidate_dob: yep.date().test(
        "DOB",
        "Age should be more than 18 years",
        value => {
            return moment().diff(moment(value), 'years') >= 18;
        }).required("Dob required"),
    candidate_address: yep.string().trim().required("Address Required"),
    candidate_experience: yep.number().test('Is positive?', 'Experience must be greater than 0!', (value) => value > 0).max(50).required("Experience required"),
    candidate_applied_dept: yep.string().trim().required("Department Required"),
    candidate_qualification: yep.string().trim().required("Qualification Required"),
    // file: yep.mixed().required('File is required').test(
    //     'fileFormat',
    //     'Only PDF and DOC files are allowed',
    //     (value) => {
    //         if (!value) return true; // Allow empty value
    //         return (
    //             value &&
    //             ['application/pdf', 'application/msword']
    //                 .includes(value.type)
    //         );
    //     }
    // ),

})
export const addEmployee = yep.object({
    // employee_no: yep.string().trim().required("Employee No Required"),
    employee_name: yep.string().trim().min(3, "Enter a valid name").max(30, "Name should not be longer than 30 characters").matches(name, 'Invalid Name').required("Name required"),
    employee_email: yep.string().trim().matches(email, 'Enter a valid email address').required("Email required"),
    employee_service_email: yep.string().trim().matches(email, 'Enter a valid email address').required("Service Email required"),
    employee_gender: yep.string().required("Gender required"),
    employee_dob: yep.date().test(
        "DOB",
        "Age should be more than 18 years",
        value => {
            return moment().diff(moment(value), 'years') >= 18;
        }).required("Dob required"),
    employee_mobile: yep.string().trim().min(10, "Mobile number should be 10 digit").max(10, "Mobile number should be 10 digit").matches(MobileRegExp, 'Mobile number is not valid').required("Mobile number required"),
    employee_designation: yep.string().trim().required("Designation Required"),
    employee_branch_type: yep.string().required("Branch required"),
    employee_home_address: yep.string().min(5, "Home address must be at least 5 characters").required("Home Address required"),
    employee_office_address: yep.string().min(5, "Office address must be at least 5 characters").required("Office Address required"),
    employee_present_address: yep.string().min(5, "Present address must be at least 5 characters").required("Present Address required"),
    employee_type: yep.string().required("Department required"),
    employee_probation_start: yep.date().required("Probation Start Required"),
    employee_probation_end: yep.date().required("Probation End Required")
})
export const addIntern = yep.object({
    // employee_no: yep.string().trim().required("Employee No Required"),
    employee_company: yep.string().required("Company Name required"),
    employee_name: yep.string().trim().min(3, "Enter a valid name").max(30, "Name should not be longer than 30 characters").matches(name, 'Invalid Name').required("Name required"),
    employee_email: yep.string().trim().matches(email, 'Enter a valid email address').required("Email required"),
    employee_service_email: yep.string().trim().matches(email, 'Enter a valid email address').required("Service Email required"),
    employee_gender: yep.string().required("Gender required"),
    employee_dob: yep.date().test(
        "DOB",
        "Age should be more than 18 years",
        value => {
            return moment().diff(moment(value), 'years') >= 18;
        }).required("Dob required"),
    employee_mobile: yep.string().trim().min(10, "Mobile number should be 10 digit").max(10, "Mobile number should be 10 digit").matches(MobileRegExp, 'Mobile number is not valid').required("Mobile number required"),
    employee_designation: yep.string().trim().required("Designation Required"),
    employee_branch_type: yep.string().required("Branch required"),
    employee_home_address: yep.string().min(5, "Home address must be at least 5 characters").required("Home Address required"),
    employee_office_address: yep.string().min(5, "Office address must be at least 5 characters").required("Office Address required"),
    employee_present_address: yep.string().min(5, "Present address must be at least 5 characters").required("Present Address required"),
})
export const addLeave = yep.object({
    leave_types_id: yep.string().required("Leave Type Required"),
    leave_day_type: yep.string().required("Choose The Day"),
    leave_date_start: yep.string().required("Start date is require"),
    leave_date_end: yep.string().required("End date is require"),
    leave_reason: yep.string().required("Reason Require")
})
export const addEducation = yep.object({
    education_institute: yep.string().trim().required("Institute Name Required"),
    education_degree: yep.string().trim().required("Degree Required"),
    education_field: yep.string().trim().required("Field Required"),
    education_mark: yep.string().trim().required("Mark Required"),
    education_start: yep.string().trim().required("Start Date Required"),
    education_end: yep.string().trim().required("End Date Required"),
})
export const addCompany = yep.object({
    company_name: yep.string().trim().required("Company Name Required"),
    company_location: yep.string().trim().required("Company Location Required"),
    company_gst: yep.string().trim().required("Company Gst Required"),
    company_email: yep.string().trim().required("Company Email Required"),
    company_website: yep.string().trim().required("Company Website Required")
})
export const addBranch = yep.object({
    company_id: yep.number().required("Company Name Required"),
    branch_name: yep.string().trim().required("Branch Name Required"),
    branch_location: yep.string().trim().required("Branch Location Required"),
    branch_email: yep.string().trim().required("Branch Email Required"),
    // branch_manager: yep.string().trim().required("Branch Manager Required"),
})
export const addExperience =
    yep.array().of(
        yep.object().shape({
            experience_organisation: yep.string().required('Required'),
            experience_type: yep.string().required('Required'),
            experience_title: yep.string().required('Required'),
            experience_start: yep.date().required('Required'),
            experience_end: yep.date().required('Required'),
        })
    )
