import { useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
const PrivateRoutes = () => {
  const accessToken = localStorage.getItem("Att_token");
  const [loginToken, setLoginToken] = useState(Boolean(accessToken));
  const InCorrectRoute = () => {
    return <Navigate to="/login" />;
  };
  const EndSession = () => {
    return <Outlet />;
  };

  return loginToken ? <EndSession /> : <InCorrectRoute />;
};
export default PrivateRoutes;
