import React, { useState, useEffect, useMemo } from "react";
import Dashboard from "../Dashboard";
import { Link } from "react-router-dom";
import { Drawer, Box, Pagination } from "@mui/material";
import Axios from "axios";
import { toast } from "react-toastify";
import ApiConfig from "../../Config/APIConfig";
import ButtonCircleLoader from "../../CircleLoading/ButtonCircleLoader";
import moment from "moment";
import PageHead from "../PageHead";
import { Form, Formik, Field } from "formik";
import { addCandidate } from "../schema";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
const CandidateEnroll = () => {
  const [candidateData, setCandidateData] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [statusDrawer, setStatusDrawer] = useState(false);
  const [drawerHead, setDrawerHead] = useState("");
  const [statusDrawerData, setStatusDrawerData] = useState("");
  const [candidateStatus, setCandidateStatus] = useState("");
  let currentDate = new Date().toJSON().slice(0, 10);
  useEffect(() => {
    setCandidateStatus({
      candidate_id: statusDrawerData?.candidate_id,
      candidate_status: statusDrawerData?.candidate_status,
      candidate_interview_date: statusDrawerData?.candidate_interview_date,
      candidate_interview_time: statusDrawerData?.candidate_interview_time,
      candidate_remark: statusDrawerData?.candidate_remarks,
    });
  }, [statusDrawerData]);

  const handlestatusData = (e) => {
    const { name, value } = e.target;
    setCandidateStatus({
      ...candidateStatus,
      [name]: value,
    });
  };
  const [token, setToken] = useState("");
  useEffect(() => {
    setToken(JSON.parse(localStorage.getItem("Att_token")));
  }, []);
  const [company, setCompany] = useState([]);
  const getCompany = async () => {
    if (token) {
      await Axios.get(ApiConfig.getCompanies, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          res?.data?.code === "200" ? setCompany(res?.data?.data) : setCompany([]);
        })
        .catch((err) => {
          throw new Error(err);
        });
    }
  };
  useEffect(() => {
    getCompany();
  }, [token]);
  const [callbyData, setCallbyData] = useState("");
  // console.log(callbyData);
  useMemo(() => (!isDrawerOpen ? setCallbyData("") : null), [isDrawerOpen]);
  const initialValue = {
    candidate_company: callbyData?.candidate_company,
    candidate_mobile: callbyData?.candidate_mobile,
    candidate_name: callbyData?.candidate_name,
    candidate_email: callbyData?.candidate_email,
    candidate_gender: callbyData?.candidate_gender,
    candidate_dob: callbyData?.candidate_dob,
    candidate_address: callbyData?.candidate_address,
    candidate_experience: callbyData?.candidate_experience,
    candidate_id: callbyData?.candidate_id,
    candidate_applied_dept: callbyData?.candidate_applied_dept,
    candidate_interview_date: callbyData?.candidate_interview_date,
    candidate_interview_time: callbyData?.candidate_interview_time,
    candidate_qualification: callbyData?.candidate_qualification,
    candidate_remarks: callbyData?.candidate_remarks,
  };
  const [isLoading, setIsLoading] = useState(false);
  const handleAddCandidate = async (val) => {
    setIsLoading(true);
    try {
      const res = await Axios.post(ApiConfig.addCandidate, { candidate_company: parseInt(val?.candidate_company), ...val });
      if (res.status === 200) {
        // console.log(res);
        toast.success("Candidate Added Successfully");
        handleUploadResume(res.data.data);
        getCandidateLists();
        setIsDrawerOpen(false);
      } else {
        toast.error("Candidate Added Failed");
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response.data.code === "404") {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.response.data.message);
      }
    }
  };
  const [counter, setCounter] = useState(0);
  const [count, setCount] = useState("");
  const [page, setPage] = useState(1);
  const limit = 10;
  const getCandidateLists = async () => {
    try {
      const res = await Axios.get(`${ApiConfig.getCandidateDetails}&pagination=true&limit=${limit}&page=${page}`);
      if (res.status === 200) {
        setCount(res?.data?.count)
        setCandidateData(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCandidateLists();
  }, [counter, page]);
  const [buttonID, setButtonID] = useState();
  const handleGetResume = async (id) => {
    setButtonID(id);
    setIsLoading(true);
    try {
      const res = await Axios.post(ApiConfig.viewResume, {
        candidate_id: id,
      });
      if (res.status == 200) {
        if (res?.data?.data[0]?.files_details === null) {
          toast.warning("Resume not Found");
        } else {
          openResume(res?.data?.data[0]?.files_details);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("No Data Found");
    }
  };
  const openResume = (data) => {
    setTimeout(() => {
      window.open(`${ApiConfig.openResumeNewTab}/${data}/play?_format=json`, "_blank")?.focus();
      setIsLoading(false);
    }, 500);
  };

  const handleStatusUpdate = async (e) => {
    e.preventDefault();
    try {
      const res = await Axios.post(ApiConfig.sendCandidateStatus, {
        candidate_id: candidateStatus?.candidate_id,
        candidate_status: candidateStatus?.candidate_status,
        candidate_interview_date: candidateStatus?.candidate_interview_date,
        candidate_interview_time: candidateStatus?.candidate_interview_time,
        candidate_remarks: candidateStatus?.candidate_remark,
      });
      if (res.status === 200) {
        setStatusDrawer(false);
        getCandidateLists();
        toast.success("Status Updated Successfully");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
    }
  };
  const [file, setFile] = useState(null);
  const handleEditCandidate = async (val) => {
    // console.log(val)
    setIsLoading(true);
    delete callbyData.candidate_status;
    delete callbyData.candidate_updated_at;
    delete callbyData.candidate_updated_by;
    delete callbyData.candidate_created_at;
    delete callbyData.candidate_created_by;
    delete callbyData.candidate_is_called;
    delete callbyData.candidate_letter;
    try {
      const res = await Axios.post(ApiConfig.editCandidate, val);
      if (res.status === 200) {
        setIsLoading(false);
        setIsDrawerOpen(false);
        if (file !== null) {
          handleUploadResume();
        }
        toast.success("Candidate updated successfully");
        getCandidateLists();
      } else {
        toast.error("Candidate update failed");
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("something went wrong");
      console.log(error);
    }
  };
  const handleResumeUpload = (e) => {
    setFile(e.target.files[0]);
  };
  // console.log(file)
  const [resumeURL, setResumeURL] = useState("");

  const handleUploadResume = async (id) => {
    // console.log(file);
    const formData = new FormData();
    formData.append("user_file", file);
    formData.append("candidate_id", id ? id : callbyData.candidate_id);
    try {
      const res = await Axios.post(ApiConfig.sendCandidateResume, formData);
      if (res.status == 200) {
        setIsLoading(false);
        setResumeURL(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Send Offer Letter Api
  const [id, setId] = useState("");
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [letterLoader, setLetterLoader] = useState(false);
  const sendLetter = (val) => {
    setId(val.candidate_id);
    setOpenConfirmation(true);
  };
  const handleSendLetter = async () => {
    setLetterLoader(true);
    await Axios.post(ApiConfig.sendOfferLetter, {
      candidate_id: id,
    })
      .then((res) => {
        // console.log(res);
        if (res.data?.code === "200") {
          setLetterLoader();
          setOpenConfirmation();
          toast.success(res.data?.message);
        } else {
          setLetterLoader();
          setOpenConfirmation();
          toast.success(res.data?.message);
        }
      })
      .catch((err) => {
        setLetterLoader();
        setOpenConfirmation();
        // console.log(err.response.data.message);
      });
  };

  const handleCallStatus = async (val) => {
    let { candidate_id, candidate_is_called } = val;
    if (candidate_is_called === 0) {
      candidate_is_called = 1;
    } else {
      candidate_is_called = 0;
    }
    await Axios.post(ApiConfig.updateCandidateCallStatus, {
      candidate_id: candidate_id,
      candidate_is_called: candidate_is_called,
    })
      .then((res) => {
        console.log(res);
        if (res.data.code === "200") {
          setCounter((c) => c + 1);
          toast.success(res.data.message);
        } else {
          setCounter((c) => c + 1);
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // console.log(candidateStatus);
  return (
    <>
      <Dashboard>
        <PageHead title="Attendify || Candidate" />
        <div className="page">
          <div className="page-wrapper" style={{ padding: "0px 10px" }}>
            <div className="container-lg container-main">
              <div className="page-header d-print-none mb-3">
                <div
                  style={{
                    display: "flex",
                    flexwrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      marginRight: "15px",
                    }}
                  >
                    <h2 className="">Candidate List</h2>
                  </div>
                  <div className="d-print-none">
                    <div className="btn-list">
                      <button
                        onClick={() => {
                          setIsDrawerOpen(true);
                          setDrawerHead("Add");
                        }}
                        className="btn btn-primary d-none d-sm-inline-block"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <line x1="12" y1="5" x2="12" y2="19" />
                          <line x1="5" y1="12" x2="19" y2="12" />
                        </svg>
                        Add Candidate
                      </button>
                      <button className="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal" data-bs-target="#modal-report" aria-label="Create new report">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <line x1="12" y1="5" x2="12" y2="19" />
                          <line x1="5" y1="12" x2="19" y2="12" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card container-lg container-main">
              <div className="table-responsive">
                {/* IN TABLE TAG REMOVE ......table-mobile-md..... */}
                <table className="table table-vcenter card-table">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Candidate Name</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      <th>Gender</th>
                      <th>Date of Birth</th>
                      <th>Address</th>
                      <th>Resume</th>
                      <th>Status</th>
                      <th>Call Status</th>
                      <th>Letter Status</th>
                      <th>Letter/Mail</th>
                      <th>
                        <div align="center">Actions</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {candidateData &&
                      candidateData.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td className="text" data-label="Role">
                              {index + 1}
                            </td>
                            <td className="text" data-label="Role">
                              {item.candidate_name}
                            </td>
                            <td className="text" data-label="Role">
                              {item.candidate_email}
                            </td>
                            <td className="text" data-label="Role">
                              {item.candidate_mobile}
                            </td>
                            <td className="text" data-label="Role">
                              {item.candidate_gender === 1 ? "Male" : item.candidate_gender === 2 ? "Female" : null}
                            </td>
                            <td className="text" data-label="Role">
                              {moment(item.dob).format("DD-MM-YYYY")}
                            </td>
                            <td className="text" data-label="Role">
                              <div className="textTruncate">{item.candidate_address}</div>
                            </td>
                            <td className="text" data-label="Role">
                              <Link
                                to=""
                                onClick={() => {
                                  handleGetResume(item?.candidate_id);
                                }}
                                className="btn btn-primary"
                              >
                                View Resume {buttonID === item?.candidate_id && isLoading && <ButtonCircleLoader />}
                              </Link>
                            </td>
                            <td className="text" data-label="Role">
                              <span
                                className={
                                  item.candidate_status === 1
                                    ? "text-color-yellow"
                                    : item.candidate_status === 2
                                      ? "text-color-orange"
                                      : item.candidate_status === 3
                                        ? "text-color-green"
                                        : item.candidate_status === 4
                                          ? "text-color-red"
                                          : null
                                }
                              >
                                {item.candidate_status === 1
                                  ? "Enrolled"
                                  : item.candidate_status === 2
                                    ? "Interview"
                                    : item.candidate_status === 3
                                      ? "Selected"
                                      : item.candidate_status === 4
                                        ? "Rejected"
                                        : null}
                              </span>
                            </td>
                            <td className="text-center">
                              {item?.candidate_is_called === 1 ? (
                                <i onClick={() => handleCallStatus(item)} className="far fa-check-circle text-success pe-auto"></i>
                              ) : (
                                <i onClick={() => handleCallStatus(item)} className="far fa-times-circle text-danger pe-auto"></i>
                              )}
                            </td>
                            <td className="text-center">
                              {item?.candidate_letter === "" || item?.candidate_letter === null ? <i className="far fa-times-circle text-danger"></i> : <i className="far fa-check-circle text-success"></i>}
                            </td>
                            <td>
                              {item.candidate_status === 3 ? (
                                <button className="btn" onClick={() => sendLetter(item)}>
                                  {item?.candidate_letter === "" ? "Send Letter" : "Re-Send Letter"}
                                </button>
                              ) : item.candidate_status === 4 ? (
                                "Rejected"
                              ) : (
                                "In Process"
                              )}
                            </td>
                            <td>
                              <div className="btn-list flex-nowrap">
                                <div className="dropdown">
                                  <button className="btn dropdown-toggle align-text-top" data-bs-toggle="dropdown" aria-expanded="false">
                                    Actions
                                  </button>
                                  <div className="dropdown-menu dropdown-menu-end">
                                    <div
                                      onClick={() => {
                                        setIsDrawerOpen(true);
                                        setDrawerHead("View");
                                        setCallbyData(item);
                                      }}
                                      className="dropdown-item"
                                    >
                                      View
                                    </div>
                                    <div
                                      onClick={() => {
                                        setIsDrawerOpen(true);
                                        setDrawerHead("Edit");
                                        setCallbyData(item);
                                      }}
                                      className="dropdown-item"
                                    >
                                      Edit
                                    </div>
                                    <div
                                      onClick={() => {
                                        setStatusDrawer(true);
                                        setStatusDrawerData(item);
                                      }}
                                      className="dropdown-item"
                                    >
                                      Candidate Status
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="card-footer d-flex align-items-center justify-content-between">
                <p className="m-0 text-muted">
                  Showing <span>1</span> to <span>8</span> of <span>16</span> entries
                </p>
                <div>
                  <Pagination color="primary" count={Math.ceil(count / limit)} onChange={(e, value) => setPage(value)} />
                </div>
              </div>
            </div>
            {/* candidate Viewing adding and Edditing */}
            <Drawer anchor="right" className="modal-blur" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
              <Formik
                initialValues={initialValue}
                initialTouched={{
                  field: true,
                }}
                validationSchema={addCandidate}
                enableReinitialize
                onSubmit={(values) => {
                  if (drawerHead === "Add") {
                    return handleAddCandidate(values);
                  } else {
                    return handleEditCandidate(values);
                  }
                }}
              >
                {({ errors, handleBlur, handleChange, touched, values }) => {
                  // console.log(errors);
                  return (
                    <Form>
                      <Box width="50rem">
                        <div className="modal-dialog modal-lg" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title">
                                {drawerHead === "Add" ? "Add " : drawerHead === "Edit" ? "Edit " : drawerHead === "View" ? "View " : ""}
                                Candidate
                              </h5>
                            </div>
                            <div className="modal-body">
                              <div className="form-selectgroup-boxes row mb-3">
                                <div className="col-md-6 mb-3">
                                  <label className="form-label">Choose Company</label>
                                  <Field as="select" className="form-select" onChange={handleChange} onBlur={handleBlur} name="candidate_company" value={values?.candidate_company || ""}>
                                    <option value="">---Choose Company---</option>
                                    {company?.map((val, i) => {
                                      return (
                                        <option key={i} value={val?.company_id}>
                                          {val?.company_name}
                                        </option>
                                      );
                                    })}
                                  </Field>
                                  {errors?.candidate_company && touched.candidate_company ? <p className="form-valid">{errors?.candidate_company}</p> : null}
                                </div>
                                <div className="col-lg-6">
                                  <label className="form-label">Cadidate Name</label>
                                  <Field
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="form-control"
                                    value={values?.candidate_name || ""}
                                    name="candidate_name"
                                    readOnly={drawerHead === "View" ? true : false}
                                    placeholder="Enter candidate name"
                                  />
                                  {errors?.candidate_name && touched.candidate_name ? <p className="form-valid">{errors?.candidate_name}</p> : null}
                                </div>

                                <div className="col-lg-6 mb-3">
                                  <label className="form-label">Candidate Mobile</label>
                                  <Field
                                    type="number"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="form-control"
                                    readOnly={drawerHead === "View" ? true : false}
                                    name="candidate_mobile"
                                    value={values?.candidate_mobile || ""}
                                    placeholder="Enter candidate mobile"
                                  />
                                  {errors?.candidate_mobile && touched.candidate_mobile ? <p className="form-valid">{errors?.candidate_mobile}</p> : null}
                                </div>

                                <div className="col-lg-6">
                                  <label className="form-label">Candidate Email</label>
                                  <Field
                                    type="email"
                                    className="form-control"
                                    // value={data.email}
                                    value={values?.candidate_email || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    readOnly={drawerHead === "View" ? true : false}
                                    name="candidate_email"
                                    placeholder="Enter candidate email"
                                  />
                                  {errors?.candidate_email && touched.candidate_email ? <p className="form-valid">{errors?.candidate_email}</p> : null}
                                </div>

                                <div className="col-lg-6 mb-3">
                                  <div>
                                    <label className="form-label">Candidate Gender</label>
                                    {drawerHead === "View" ? (
                                      <Field
                                        className="form-control"
                                        value={callbyData.candidate_gender === 1 ? "Male" : callbyData.candidate_gender === 2 ? "Female" : null}
                                        readOnly
                                        name="candidate_gender"
                                      />
                                    ) : (
                                      <Field as="select" className="form-select" onChange={handleChange} onBlur={handleBlur} name="candidate_gender" value={values?.candidate_gender || ""}>
                                        <option value="">Select</option>
                                        <option value="1">Male</option>
                                        <option value="2">Female</option>
                                      </Field>
                                    )}
                                  </div>
                                  {errors?.candidate_gender && touched.candidate_gender ? <p className="form-valid">{errors?.candidate_gender}</p> : null}
                                </div>
                                <div className="col-lg-6">
                                  <div className="">
                                    <label className="form-label">Candidate Date of Birth</label>
                                    <Field
                                      type="date"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      readOnly={drawerHead === "View" ? true : false}
                                      name="candidate_dob"
                                      value={values?.candidate_dob || ""}
                                      className="form-control"
                                    />
                                    {errors?.candidate_dob && touched.candidate_dob ? <p className="form-valid">{errors?.candidate_dob}</p> : null}
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-3">
                                  <label className="form-label">Candidate Experience</label>
                                  <Field
                                    type="text"
                                    className="form-control"
                                    name="candidate_experience"
                                    value={values?.candidate_experience || ""}
                                    placeholder="Enter candidate experience"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    readOnly={drawerHead === "View" ? true : false}
                                  />
                                  {errors?.candidate_experience && touched.candidate_experience ? <p className="form-valid">{errors?.candidate_experience}</p> : null}
                                </div>
                                <div className="col-lg-6">
                                  <div className="">
                                    <label className="form-label">Candidate Applying Department</label>
                                    {drawerHead === "View" ? (
                                      <Field
                                        className="form-control"
                                        value={
                                          callbyData.candidate_applied_dept === 1
                                            ? "IT"
                                            : callbyData.candidate_applied_dept === 2
                                              ? "HR"
                                              : callbyData.candidate_applied_dept === 3
                                                ? "Sales"
                                                : callbyData.candidate_applied_dept === 4
                                                  ? "Operations"
                                                  : callbyData.candidate_applied_dept === 5
                                                    ? "Staff"
                                                    : "Other"
                                        }
                                        readOnly
                                      />
                                    ) : (
                                      <Field as="select" className="form-select" onChange={handleChange} onBlur={handleBlur} value={values?.candidate_applied_dept || ""} name="candidate_applied_dept">
                                        <option value="">Select</option>
                                        <option value="1">IT</option>
                                        <option value="2">HR</option>
                                        <option value="3">Sales</option>
                                        <option value="4">Operations</option>
                                        <option value="5">Staff</option>
                                      </Field>
                                    )}
                                  </div>
                                  {errors?.candidate_applied_dept && touched.candidate_applied_dept ? <p className="form-valid">{errors?.candidate_applied_dept}</p> : null}
                                </div>
                                {drawerHead === "View" || drawerHead === "Add" ? (
                                  <div className="col-lg-6 mb-3">
                                    <div className="">
                                      <label className="form-label">Candidate Qualification</label>
                                      <Field
                                        className="form-control"
                                        name="candidate_qualification"
                                        value={values?.candidate_qualification || ""}
                                        placeholder="Enter candidate qualification"
                                        onChange={handleChange}
                                        readOnly={drawerHead === "View" ? true : false}
                                      />
                                      {errors?.candidate_qualification && touched.candidate_qualification ? <p className="form-valid">{errors?.candidate_qualification}</p> : null}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <label htmlFor="formFile" className="form-label">
                                      Upload Resume
                                    </label>
                                    <Field className="form-control" type="file" accept=".pdf,.doc,.docx,application/msword" name="file" onChange={handleResumeUpload} onBlur={handleBlur} />
                                    {/* {errors?.file && touched?.file ? <p className="form-valid">{errors?.file}</p> : null} */}
                                  </div>
                                </div>
                                <div className="col-lg-6 mb-3">
                                  <label className="form-label">Candidate Address</label>
                                  <textarea
                                    placeholder="Enter candidate address"
                                    className="form-control"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    readOnly={drawerHead === "View" ? true : false}
                                    name="candidate_address"
                                    value={values?.candidate_address || ""}
                                    rows="3"
                                  ></textarea>
                                  {errors?.candidate_address && touched.candidate_address ? <p className="form-valid">{errors?.candidate_address}</p> : null}
                                </div>
                              </div>
                            </div>

                            <div className="modal-body">
                              {drawerHead === "View" ? (
                                <>
                                  <div className="form-selectgroup-boxes row mb-3">
                                    <div className="col-lg-6 mb-3">
                                      <label className="form-label">Candidate Status</label>
                                      <Field
                                        className="form-control"
                                        name="candidate_experience"
                                        value={
                                          callbyData.candidate_status === 1
                                            ? "Enrolled"
                                            : callbyData.candidate_status === 2
                                              ? "Interview"
                                              : callbyData.candidate_status === 3
                                                ? "Selected"
                                                : callbyData.candidate_status === 4
                                                  ? "Rejected"
                                                  : null
                                        }
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                  <div className="form-selectgroup-boxes row mb-3">
                                    <div className="col-lg-6 mb-3">
                                      <label className="form-label">Candidate Interview Date</label>
                                      <Field type="date" className="form-control" name="candidate_interview_date" value={callbyData.candidate_interview_date} readOnly />
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="">
                                        <label className="form-label">Candidate Interview Time</label>
                                        <Field type="time" className="form-control" name="candidate_interview_time" value={callbyData.candidate_interview_time} readOnly />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-12 mb-3">
                                      <label className="form-label">Candidate Remark</label>
                                      <textarea className="form-control" readOnly name="candidate_remarks" value={callbyData.candidate_remarks} rows="3"></textarea>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="" align="right">
                              <span
                                onClick={() => {
                                  setIsDrawerOpen(false);
                                }}
                                className="btn btn-secondary"
                                style={{ marginRight: "10px" }}
                              >
                                Close
                              </span>
                              {drawerHead === "Edit" || drawerHead === "Add" ? (
                                <button type="submit" className="btn btn-primary">
                                  Submit {isLoading && <ButtonCircleLoader />}
                                </button>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </Box>
                    </Form>
                  );
                }}
              </Formik>
            </Drawer>

            {/* candidate Status Updating here */}
            <Drawer anchor="right" className="modal-blur" open={statusDrawer} onClose={() => setStatusDrawer(false)}>
              <form>
                <Box width="50rem">
                  <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">Status Candidate</h5>
                      </div>
                      <div className="modal-body">
                        <div className="form-selectgroup-boxes row mb-3">
                          <div className="col-lg-6 mb-3">
                            <label className="form-label">Candidate Enrollment Number</label>
                            <input type="text" readOnly name="candidate_id" value={candidateStatus?.candidate_id} className="form-control" placeholder="Enter candidate enrollment Number" />
                          </div>
                          <div className="col-lg-6 mb-3">
                            <div className="">
                              <label className="form-label">Candidate Status</label>
                              <select className="form-select" name="candidate_status" value={candidateStatus?.candidate_status} onChange={handlestatusData}>
                                <option value="" selected>
                                  Choose Status
                                </option>
                                <option value="1">Enrolled</option>
                                <option value="2">Interview</option>
                                <option value="3">Selected</option>
                                <option value="4">Rejected</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        {candidateStatus?.candidate_status == "2" && (
                          <div className="form-selectgroup-boxes row mb-3">
                            <div className="col-lg-6">
                              <div>
                                <label className="form-label">Candidate Interview Date</label>
                                <input type="date" value={candidateStatus?.candidate_interview_date} min={currentDate} onChange={handlestatusData} name="candidate_interview_date" className="form-control" />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <label className="form-label">Candidate Interview Time</label>
                              <input
                                type="time"
                                className="form-control"
                                value={candidateStatus?.candidate_interview_time}
                                min="10:00am"
                                max="18:30pm"
                                onChange={handlestatusData}
                                name="candidate_interview_time"
                                placeholder="Enter candidate email"
                              />
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="modal-body">
                        <div className="row">
                          {candidateStatus?.candidate_status == "3" || candidateStatus?.candidate_status == "4" ? (
                            <div className="col-lg-12">
                              <div>
                                <label className="form-label">Candidate Remark</label>
                                <textarea
                                  placeholder="Enter candidate remark"
                                  className="form-control"
                                  value={candidateStatus?.candidate_remark}
                                  onChange={handlestatusData}
                                  name="candidate_remark"
                                  rows="3"
                                ></textarea>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button onClick={() => setStatusDrawer()} type="button" className="btn btn-secondary">
                          Close
                          {/* {isLoading && <ButtonCircleLoader />} */}
                        </button>
                        <button onClick={handleStatusUpdate} type="submit" value="submit" className="btn btn-primary">
                          Submit
                          {/* {isLoading && <ButtonCircleLoader />} */}
                        </button>
                      </div>
                    </div>
                  </div>
                </Box>
              </form>
            </Drawer>

            {/* Drawer end */}
          </div>
        </div>
      </Dashboard>
      <Dialog
        open={openConfirmation}
        onClose={() => setOpenConfirmation()}
        // PaperComponent={PaperComponent}
        fullWidth
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Are You Sure ?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpenConfirmation()}>
            Cancel
          </Button>
          <Button onClick={handleSendLetter} disable={letterLoader}>{letterLoader ? "Please Wait..." : "Send"}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CandidateEnroll;
