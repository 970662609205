import React, { useState, useEffect, useContext, useMemo } from "react";
import DataContext from "../Context/Context";
import Dashboard from "./Dashboard";
import { Drawer, Box } from "@mui/material";
import Axios from "axios";
import { Formik, Form, Field } from "formik"
import { addExperience, addEducation } from "./schema";
import { toast } from "react-toastify";
import ApiConfig from "../Config/APIConfig";
import { useLocation } from "react-router-dom";
import { BsPencil, BsCheckLg } from "react-icons/bs";
import { BiUserX, BiUserCheck } from "react-icons/bi";
import { AiFillSetting } from "react-icons/ai";
import moment from "moment";
import PageHead from "./PageHead";
import { degreeConfig, fileType } from "../Config/ArrayConfig";
const EmployeeView = () => {
  const [token, setToken] = useState("")
  useEffect(() => {
    setToken(JSON.parse(localStorage.getItem("Att_token")))
  }, []);
  const [getDesg, setGetDesg] = useState([]);
  const [employeeStatus, setEmployeeStatus] = useState(true);
  const context = useContext(DataContext);
  const { loginData } = context;
  const getDesignation = async () => {
    try {
      const res = await Axios.post(ApiConfig.getDesignationT, {
        // designation_status: employee_company,
        designation_status: 1,
        pagination: true,
        limit: 10,
      });
      if (res.status == 200) {
        setGetDesg(res?.data?.data);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  };
  useEffect(() => {
    getDesignation();
  }, []);
  const [leavetype, setLeaveType] = useState([]);

  const LeaveCatgeory = async () => {
    try {
      const res = await Axios.get(ApiConfig.listLeaveTypes);
      if (res.status == 200) {
        setLeaveType(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // console.log("asdasfrfg", leavetype);
  useEffect(() => {
    LeaveCatgeory();
  }, []);

  // const [departList, setDeprtList] = useState();
  // const DepartmentList = async () => {
  //   try {
  //     const res = await Axios.post(ApiConfig.departmentList, {
  //       // department_status: employee_company,
  //       department_status: 1,
  //       pagination: true,
  //       limit: 10,
  //     });
  //     if (res.status === 200) {
  //       setDeprtList(res.data.data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // useEffect(() => {
  //   DepartmentList();
  // }, []);

  const location = useLocation();
  const EmployeData = location?.state;
  const [buttontab, setButtontab] = useState("Experience");
  // console.log(EmployeData);

  //   ------------------------------------

  // const EmployeeDetailTab = () => {
  //   const [empdata, setEmpData] = useState("View");

  //   const [employeeEditData, setEmployeeEditdData] = useState({
  //     employee_no: EmployeData?.employee_no,
  //     employee_name: EmployeData?.employee_name,
  //     employee_service_email: EmployeData?.employee_service_email,
  //     employee_gender: EmployeData?.employee_gender,
  //     employee_dob: EmployeData?.employee_dob,
  //     employee_mobile: EmployeData?.employee_mobile,
  //     employee_designation: EmployeData?.designation_name,
  //     employee_branch_type: EmployeData?.employee_branch_type,
  //     employee_type: EmployeData?.department_name,
  //   });

  //   const handleEditInput = (e) => {
  //     const { name, value } = e.target;
  //     setEmployeeEditdData({
  //       ...employeeEditData,
  //       [name]: value,
  //     });
  //   };

  //   const handleReset = () => {
  //     setEmployeeEditdData({
  //       employee_no: "",
  //       employee_name: "",
  //       employee_service_email: "",
  //       employee_gender: "",
  //       employee_dob: "",
  //       employee_mobile: "",
  //       employee_designation: "",
  //       employee_branch_type: "",
  //       employee_type: "",
  //     });
  //   };

  //   const handleEditSubmit = async (e) => {
  //     e.preventDefault();
  //     try {
  //       const res = await Axios.post(ApiConfig.employeeEditDetails, {
  //         employee_id: EmployeData?.employee_id,
  //         employee_no: employeeEditData?.employee_no,
  //         employee_name: employeeEditData?.employee_name,
  //         employee_service_email: employeeEditData?.employee_service_email,
  //         employee_gender: employeeEditData?.employee_gender,
  //         employee_dob: employeeEditData?.employee_dob,
  //         employee_mobile: employeeEditData?.employee_mobile,
  //         employee_designation: employeeEditData?.employee_designation,
  //         employee_branch_type: employeeEditData?.employee_branch_type,
  //         employee_type: employeeEditData?.employee_type,
  //       });
  //       if (res.status == 200 || res.status == 201) {
  //         setEmpData("View");
  //         toast.success("Employee Details updated");
  //       }
  //     } catch (error) {
  //       toast.error("Something Went wrong");
  //     }
  //   };

  //   return (
  //     <div className="">
  //       <form>
  //         <Box style={{ padding: "15px" }}>
  //           <div className="modal-content">
  //             <div className="modal-header ">
  //               <h5 className="modal-title">{empdata} Employee Details</h5>
  //               <div className="btnContainer">
  //                 {empdata === "View" ? (
  //                   <button
  //                     onClick={() => {
  //                       setEmpData("Edit");
  //                     }}
  //                     className="btn btn-primary "
  //                   >
  //                     <BsPencil /> &nbsp; Edit
  //                   </button>
  //                 ) : null}

  //                 {employeeStatus ? (
  //                   <button
  //                     className="btn btn-success ms-auto"
  //                     onClick={() => {
  //                       setEmployeeStatus(false);
  //                     }}
  //                   >
  //                     <BiUserCheck style={{ fontSize: "20px" }} /> &nbsp; Active
  //                   </button>
  //                 ) : (
  //                   <button
  //                     className="btn btn-danger ms-auto"
  //                     onClick={() => {
  //                       setEmployeeStatus(true);
  //                     }}
  //                   >
  //                     <BiUserX style={{ fontSize: "20px" }} /> &nbsp; Deactive
  //                   </button>
  //                 )}
  //               </div>
  //             </div>
  //             <div className="modal-body bg-white">
  //               <div className="form-selectgroup-boxes row">
  //                 <div className="col-lg-4 col-md-6 col-xs-6 mb-3">
  //                   <label className="form-label">EMPLOYEE NO</label>
  //                   <input type="text" className="form-control" name="example-text-input" readOnly={empdata === "View" ? true : false} value={employeeEditData?.employee_no} placeholder="Your report name" />
  //                 </div>
  //                 <div className="col-lg-4 col-md-6 col-xs-6 mb-3">
  //                   <label className="form-label">EMPLOYEE NAME</label>
  //                   <input
  //                     type="text"
  //                     onChange={handleEditInput}
  //                     value={employeeEditData?.employee_name}
  //                     className="form-control"
  //                     readOnly={empdata === "View" ? true : false}
  //                     name="employee_name"
  //                     placeholder="employee_name"
  //                   />
  //                 </div>
  //                 <div className="col-lg-4 col-md-6 col-xs-6 mb-3">
  //                   <label className="form-label">EMPLOYEE MOBILE</label>
  //                   <input
  //                     type="number"
  //                     className="form-control"
  //                     onChange={handleEditInput}
  //                     name="employee_mobile"
  //                     readOnly={empdata === "View" ? true : false}
  //                     value={employeeEditData?.employee_mobile}
  //                     placeholder="employee_mobile"
  //                   />
  //                 </div>
  //                 <div className="col-lg-4 col-md-6 col-xs-6 mb-3">
  //                   <label className="form-label">EMPLOYEE SERVICE EMAIL</label>
  //                   <div className="input-group input-group-flat">
  //                     <input
  //                       type="email"
  //                       readOnly={empdata === "View" ? true : false}
  //                       value={employeeEditData?.employee_service_email}
  //                       className="form-control"
  //                       onChange={handleEditInput}
  //                       name="employee_service_email"
  //                       placeholder="employee_service_emaill"
  //                     />
  //                   </div>
  //                 </div>
  //                 <div className="col-lg-4 col-md-6 col-xs-6 mb-3">
  //                   <label className="form-label">EMPLOYEE GENDER</label>
  //                   {empdata === "View" ? (
  //                     <input value={employeeEditData?.employee_gender === 1 ? "Male" : employeeEditData?.employee_gender === 2 ? "Female" : null} type="text" name="employee_gender" readOnly className="form-control" />
  //                   ) : empdata === "Edit" ? (
  //                     <select className="form-select" onChange={handleEditInput} name="employee_gender" value={employeeEditData?.employee_gender}>
  //                       <option value="1">Male</option>
  //                       <option value="2">Female</option>
  //                     </select>
  //                   ) : null}
  //                 </div>
  //                 <div className="col-lg-4 col-md-6 col-xs-6 mb-3">
  //                   <label className="form-label">EMPLOYEE BRANCH TYPE</label>
  //                   {empdata === "View" ? (
  //                     <input
  //                       value={
  //                         employeeEditData?.employee_branch_type === 1
  //                           ? "Recharge Kit"
  //                           : null || employeeEditData?.employee_branch_type === 2
  //                             ? "Refab"
  //                             : null || employeeEditData?.employee_branch_type === 3
  //                               ? "Renxt"
  //                               : employeeEditData?.employee_branch_type === 4
  //                                 ? "Qubeinfo"
  //                                 : null
  //                       }
  //                       type="text"
  //                       readOnly
  //                       className="form-control"
  //                     />
  //                   ) : empdata === "Edit" ? (
  //                     <select className="form-select" onChange={handleEditInput} name="employee_branch_type" value={employeeEditData?.employee_branch_type}>
  //                       <option value="">Select</option>
  //                       <option value="1">ReChargeKit</option>
  //                       <option value="2">Refab</option>
  //                       <option value="3">Renxt</option>
  //                       <option value="4">Qubeinfo</option>
  //                     </select>
  //                   ) : null}
  //                 </div>
  //                 <div className="col-lg-4 col-md-6 col-xs-6 mb-3">
  //                   <label className="form-label">EMPLOYEE DESIGNATION</label>
  //                   {empdata === "View" ? (
  //                     <input value={employeeEditData?.employee_designation} type="text" readOnly className="form-control" />
  //                   ) : empdata === "Edit" ? (
  //                     <select className="form-select" onChange={handleEditInput} name="employee_designation" value={employeeEditData?.employee_designation}>
  //                       <option value="">Select</option>
  //                       {getDesg.map((item, i) => {
  //                         return (
  //                           <option key={i} value={item?.designation_id}>
  //                             {item?.designation_name}
  //                           </option>
  //                         );
  //                       })}
  //                     </select>
  //                   ) : null}
  //                 </div>
  //                 <div className="col-lg-4 col-md-6 col-xs-6 mb-3">
  //                   <label className="form-label">EMPLOYEE DEPARTMENT</label>
  //                   {empdata === "View" ? (
  //                     <input type="text" readOnly value={employeeEditData?.employee_type} className="form-control" />
  //                   ) : empdata === "Edit" ? (
  //                     <select className="form-select" onChange={handleEditInput} name="employee_type" value={employeeEditData?.employee_type}>
  //                       <option value="">Select</option>
  //                       {departList &&
  //                         departList.map((item, i) => {
  //                           return (
  //                             <option key={i} value={item.department_id}>
  //                               {item.department_name}
  //                             </option>
  //                           );
  //                         })}
  //                     </select>
  //                   ) : null}
  //                 </div>
  //                 <div className="col-lg-4 col-md-6 col-xs-6 mb-3">
  //                   <label className="form-label">EMPLOYEE DOB</label>
  //                   <input value={employeeEditData?.employee_dob} type="date" onChange={handleEditInput} name="employee_dob" readOnly={empdata === "View" ? true : false} className="form-control" />
  //                 </div>
  //               </div>
  //             </div>

  //             <div className="button-box-container" align="right">
  //               {empdata === "Edit" ? (
  //                 <>
  //                   <button onClick={handleReset} className="btn btn-primary ms-auto" style={{ marginRight: "10px" }} data-bs-dismiss="modal">
  //                     Reset
  //                   </button>
  //                   <button onClick={handleEditSubmit} className="btn btn-primary ms-auto" data-bs-dismiss="modal">
  //                     Submit
  //                   </button>
  //                 </>
  //               ) : null}
  //             </div>
  //           </div>
  //         </Box>
  //       </form>
  //     </div>
  //   );
  // };
  const EmployeeLeaveTab = () => {
    const [modalClose, setModalClose] = useState(false);
    // console.log(EmployeData);
    const [setting, setSetting] = useState("Edit");

    const [emp_leave, setEmp_Leave] = useState([]);
    const handleEmployeeLeave = async () => {
      try {
        const res = await Axios.post(ApiConfig.leaveApplicationListbyUser, {
          employee_id: EmployeData?.employee_id,
        });
        // console.log(res);
        if (res.status == 200) {
          setEmp_Leave(res?.data?.data);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message)
      }
    };
    useEffect(() => {
      setModalClose(true);
    }, [modalClose]);

    const [empLeavemap, setEmpLeaveMap] = useState([]);
    const [empLeaveNotAvl, setEmpLeaveNotAvl] = useState(false);
    const [leaveList, setleaveList] = useState([]);
    const [empLeaveType, setEmpLeaveType] = useState([]);
    const handleEmployeeCredit = async () => {
      try {
        const res = await Axios.post(ApiConfig.empLeaveTypeMap, {
          employee_id: EmployeData?.employee_id,
        });
        if (res.status == 200) {
          // console.log("res", JSON.parse(res.data.data[0].leave_type_credits));
          let data = JSON.parse(res.data.data[0].leave_type_credits)

          setEmpLeaveType(data);
          setEmpLeaveMap(res.data.data);
          setEmpLeaveNotAvl();
        }
      } catch (err) {
        // console.log(err);
        if (err.response.data.code === "400") {
          getLeaveTypes();
        }
      }
    };
    const getLeaveTypes = async () => {
      try {
        const res = await Axios.get(ApiConfig?.listLeaveTypes);
        // console.log(res)
        if (res.status == 200) {
          let obj = []
          res?.data?.data.map((val) => {
            return obj.push({ leave_type_id: val.leave_type_id, leave_type_code: val.leave_type_code, leave_type_name: val.leave_type_name, leave_type_description: val.leave_type_description, leave_type_default_credit: val.leave_type_default_credit, leave_type_available_credit: val.leave_type_default_credit, leave_type_status: val.leave_type_status })
          })
          setleaveList(obj);
        }
      } catch (error) {
        console.log(error);
      }
    };
    const handleChange = (e, item) => {
      // console.log(item);
    };
    useEffect(() => {
      getLeaveTypes();
      handleEmployeeLeave();
      handleEmployeeCredit();
    }, []);
    // console.log(leaveList)
    // const handleLeaveChange = (e, i) => {
    //   const { name, value } = e.target;
    //   // console.log("ss", i, name, value)
    //   let newFormValues = [...leaveList];
    //   newFormValues[i][name] = value;
    //   setleaveList(newFormValues);
    //   // console.log(e, i);
    // }
    // const handleLeaveCredit = async () => {
    //   await Axios.post(ApiConfig.empLeaveMap, {
    //     employee_id: EmployeData?.employee_id, created_by: loginData?.id, leave_type_credits: leaveList
    //   }).then((res) => {
    //     // console.log(res)
    //     if (res.data.code === "200") {
    //       toast.success(res.data.message)
    //     } else {
    //       toast.error(res.data.message)
    //     }
    //   }).catch((err) => {
    //     console.log(err)
    //   })
    // }
    return (
      <div className="">
        <Box style={{ padding: "15px" }}>
          <div className="modal-content">
            <div style={{ justifyContent: "end" }} className="modal-header">
              <div className="btnConteiner">
                <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modal-report">
                  Leave Credit
                </button>
              </div>
            </div>
          </div>
        </Box>
        <Box>
          <div className="card container-lg container-main">
            <div className="table-responsive">
              <table className="table table-vcenter card-table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>leave Code</th>
                    <th>Leave Type</th>
                    <th>Leave From</th>
                    <th>Leave To</th>
                    <th>Status</th>
                    <th>Resason</th>
                  </tr>
                </thead>
                <tbody>
                  {emp_leave &&
                    emp_leave?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className="text" data-label="Role">
                            {index + 1}
                          </td>

                          <td className="text" data-label="Role">
                            {item?.leave_type_code === null ? "N/A" : item?.leave_type_code}
                          </td>

                          <td className="text" data-label="Role">
                            {item?.leave_day_type == 1 ? "Whole Day" : item?.leave_day_type == 2 ? "Half Day" : item?.leave_day_type === null ? "N/A" : null}
                          </td>
                          <td className="text" data-label="Role">
                            {item?.leave_date_start === null ? "N/A" : moment(item?.leave_date_start).format("DD-MM-YYYY")}
                          </td>
                          <td className="text" data-label="Role">
                            {item?.leave_date_end === null ? "N/A" : moment(item?.leave_date_end).format("DD-MM-YYYY")}
                          </td>
                          <td className="text" data-label="Role">
                            <span
                              className={
                                item?.leave_status === 0
                                  ? "text-color-blue"
                                  : item?.leave_status === 1
                                    ? "text-color-green"
                                    : item?.leave_status === 2
                                      ? "text-color-red"
                                      : item?.leave_status === 3
                                        ? "text-color-orange"
                                        : null
                              }
                            >
                              {item?.leave_status === 0 ? "Pending" : item?.leave_status === 1 ? "Approved" : item?.leave_status === 2 ? "Cancelled" : item?.leave_status === 3 ? "Deny" : item?.leave_status === null ? "N/A" : null}
                            </span>
                          </td>
                          <td>
                            <div className="textTruncate">{item?.leave_reason === null ? "N/A" : item?.leave_reason}</div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </Box>

        <div className="modal modal-blur fade" id="modal-report" tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Leave Credit</h5>
                {setting == "Edit" ? (
                  <div
                    className="btn btn-primary"
                    onClick={() => {
                      setSetting("Save");
                    }}
                  >
                    <AiFillSetting />
                  </div>
                ) : setting == "Save" ? (
                  <div
                    className="btn btn-primary"
                    onClick={() => {
                      setSetting("Edit");
                    }}
                  >
                    <BsCheckLg />
                  </div>
                ) : null}
              </div>

              <div className="modal-body">
                <table className="table table-vcenter card-table">
                  <thead>
                    <tr>
                      <th scope="col">
                        <input type="checkbox" className="form-check-input" checked="" />
                      </th>
                      <th>S.No</th>
                      <th>Leave Type</th>
                      <th>Allowable</th>
                      <th>Available</th>
                    </tr>
                  </thead>
                  <tbody>
                    {empLeaveType &&
                      empLeaveType?.map((item, i) => {
                        // console.log(item)
                        return (
                          <tr key={i}>
                            <th scope="row">
                              <input
                                type="checkbox"
                                disabled={setting === "Edit" ? true : false}
                                // checked={user.selected}
                                className="form-check-input"
                                onChange={(e) => {
                                  handleChange(e, item);
                                }}
                              // onChange={(e) => this.onItemCheck(e, user)}
                              />
                            </th>
                            <td className="text" data-label="Role">
                              {i + 1}
                            </td>
                            <td className="text" data-label="Role">
                              {/* {item?.leave_type_code} : {item?.leave_type_name} */}
                              {
                                leaveList?.filter((l) => item?.leave_type_id === l?.leave_type_id).map((val, i) => {
                                  // console.log(val)
                                  return (
                                    <span>{val?.leave_type_code} : {val?.leave_type_name}</span>
                                  )
                                })
                              }
                            </td>
                            <td className="text" data-label="Role">
                              {item?.leave_type_default_credit}
                            </td>
                            <td className="text" data-label="Role">
                              {item?.leave_type_available_credit}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-primary
                  
                  "
                  onClick={() => {
                    setModalClose(false);
                  }}
                  data-bs-dismiss={modalClose ? "modal" : null}
                >
                  Cancel
                </button>
                {/* <button
                  className="btn btn-primary"
                  onClick={handleLeaveCredit}
                  data-bs-dismiss={modalClose ? true : false}
                >
                  Submit
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  };
  const EmployeeExperinceTab = () => {
    const [openExpDrawer, setOpenExpDrawer] = useState(false);
    const [empExperience, setEmpExperience] = useState([]);
    const getEmpExperience = async () => {
      await Axios.get(`${ApiConfig.getExperience}&employee_id=${EmployeData?.employee_id}`).then((res) => {
        // console.log(res)
        setEmpExperience(res?.data?.data)
      }).catch((err) => {
        // console.log(err)
      })
    }
    useEffect(() => {
      getEmpExperience();
    }, []);
    const [drawerHead, setDrawerHead] = useState("")
    const [expData, setExpData] = useState("")
    useMemo(() => !openExpDrawer ? setExpData("") : null, [openExpDrawer])
    const [experience, setExperience] = useState([{
      employee_id: EmployeData?.employee_id,
      experience_organisation: "",
      experience_type: "",
      experience_title: "",
      experience_start: "",
      experience_end: ""
    }])

    const handleIncExp = () => {
      setExperience([...experience, {
        employee_id: EmployeData?.employee_id,
        experience_organisation: "",
        experience_type: "",
        experience_title: "",
        experience_start: "",
        experience_end: ""
      }])
    }
    const handleRemoveExp = (i) => {
      const list = [...experience];
      list.splice(i, 1);
      setExperience(list);
    }
    const handleChange = (e, i) => {
      const { name, value } = e.target;
      let newFormValues = [...experience];
      newFormValues[i][name] = value;
      setExperience(newFormValues);
      // console.log(value);
    }

    // console.log(experience_type)
    const handleExperience = async (e) => {
      e.preventDefault();


      for (let i = 0; i < experience.length; i++) {
        const { experience_organisation, experience_type, experience_title, experience_start, experience_end } = experience[i];
        if (!experience_organisation || !experience_type || !experience_title || !experience_start || !experience_end) {
          toast.error("All Fields are Required");
          return
        }
      }
      await Axios.post(ApiConfig.addExperience, { "experience": experience }, {
        headers: {
          Authorization: "Bearer " + token,
        }
      }).then((res) => {
        // console.log(res)
        if (res.data.code === "200") {
          getEmpExperience();
          toast.success(res.data.message);
          setOpenExpDrawer()
          setExperience([{
            employee_id: EmployeData?.employee_id,
            experience_organisation: "",
            experience_type: "",
            experience_title: "",
            experience_start: "",
            experience_end: ""
          }])

        } else {
          toast.error(res.data.message);
        }
      }).catch((err) => {
        toast.error(err.response.data.message);
      })
    }
    const handleEditExp = async (val) => {
      await Axios.post(ApiConfig.updateExperience, { employee_id: EmployeData?.employee_id, ...val }, {
        headers: {
          Authorization: "Bearer " + token,
        }
      }).then((res) => {
        // console.log(res)
        if (res.data.code === "200") {
          getEmpExperience();
          toast.success(res.data.message);
          setOpenExpDrawer();
        } else {
          toast.error(res.data.message);
        }
      }).catch((err) => {
        toast.error(err.response.data.message)
      })
    }

    return (
      <div>
        <Box style={{ padding: "15px" }}>
          <div className="modal-content">
            <div style={{ justifyContent: "end" }} className="modal-header">
              <div className="btnConteiner">
                <button onClick={() => setOpenExpDrawer(true)} className="btn btn-primary">
                  +Add Experience
                </button>
              </div>
            </div>
          </div>
        </Box>
        <Box>
          <div className="card container-lg container-main">
            <div className="table-responsive">
              <table className="table table-vcenter card-table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Job type</th>
                    <th>Organisation</th>
                    <th>Job Title</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {empExperience?.length === 0 && <p className="mx-2">No Experince Found</p>}
                  {
                    empExperience?.map((val, i) => {
                      // console.log(val)
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{val?.experience_type === 1 ? "Full Time" : val?.experience_type === 2 ? "Part Time" : val?.experience_type === 3 ? "Internship" : "Trainee"}</td>
                          <td>{val?.experience_organisation}</td>
                          <td>{val?.experience_title}</td>
                          <td>{moment.utc(val?.experience_start).format("DD-MM-YYYY")}</td>
                          <td>{moment.utc(val?.experience_end).format("DD-MM-YYYY")}</td>
                          <td><i onClick={() => { setExpData(val); setDrawerHead("edit"); setOpenExpDrawer(true) }} className="fas fa-edit text-success"></i></td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </Box>
        <Drawer anchor="right" className="modal-blur" open={openExpDrawer} onClose={() => { setOpenExpDrawer(false); setDrawerHead("") }}>
          <Box width="40rem">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add Employee Experince</h5>
              </div>

              {/* <Formik initialValues={initialField}
                initialTouched={{
                  field: true,
                }}
                validationSchema={addExperience}
                enableReinitialize
                onSubmit={(values) => {
                  if (drawerHead === "edit") {
                    return handleEditExp(values)
                  } else {
                    return handleExperience(values)
                  }
                }
                }
              >
                {({ errors, handleBlur, handleChange, touched, values }) => {
                  // console.log(errors);
                  return ( */}
              <form>
                <div className="modal-body">
                  {
                    experience?.map((values, i) => {
                      return (
                        <div key={i} className={`container ${i !== 0 ? "mt-4" : ""}`}>
                          <div className="row">
                            <div className="col-md-6 mb-3">
                              <label className="form-label" htmlFor="organisation">Organisation Name</label>
                              <input type="text" className="form-control" name="experience_organisation" onChange={(e) => handleChange(e, i)} value={values?.experience_organisation} placeholder="Enter Organisation Name" />
                              {/* {errors?.experience_organisation && touched.experience_organisation ? <p className="form-valid">{errors?.experience_organisation}</p> : null} */}
                            </div>
                            <div className="col-md-6">
                              <label className="form-label" htmlFor="organisation">Job Type</label>
                              <select className="form-select" onChange={(e) => handleChange(e, i)} value={values?.experience_type} name="experience_type" aria-label="Default select example">
                                <option selected>Choose Job Type</option>
                                <option value="1">Full Time</option>
                                <option value="2">Part Time</option>
                                <option value="3">Internship</option>
                                <option value="4">Trainee</option>
                              </select>
                              {/* {errors?.experience_type && touched.experience_type ? <p className="form-valid">{errors?.experience_type}</p> : null} */}
                            </div>
                            <div className="col-md-6">
                              <label className="form-label" htmlFor="organisation">Job Title</label>
                              <input type="text" onChange={(e) => handleChange(e, i)} value={values?.experience_title} name="experience_title" className="form-control" placeholder="Enter Job Title" />
                              {/* {errors?.experience_title && touched.experience_title ? <p className="form-valid">{errors?.experience_title}</p> : null} */}
                            </div>
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-6">
                                  <label className="form-label" htmlFor="organisation">Start Date</label>
                                  <input type="date" onChange={(e) => handleChange(e, i)} value={values?.experience_start} name="experience_start" className="form-control" />
                                  {/* {errors?.experience_start && touched.experience_start ? <p className="form-valid">{errors?.experience_start}</p> : null} */}
                                </div>
                                <div className="col-md-6">
                                  <label className="form-label" htmlFor="organisation">End Date</label>
                                  <input type="date" onChange={(e) => handleChange(e, i)} value={values?.experience_end} name="experience_end" className="form-control" />
                                  {/* {errors?.experience_end && touched.experience_end ? <p className="form-valid">{errors?.experience_end}</p> : null} */}
                                </div>
                              </div>
                            </div>
                          </div>
                          {i !== 0 && <div onClick={() => handleRemoveExp(i)} className="d-flex justify-content-end mt-3 ">
                            <h2><i className="fas fa-trash text-danger btn"></i></h2>
                          </div>}
                          <hr />
                        </div>
                      )
                    })
                  }
                  <div className="modal-footer mt-3">
                    <div className="mx-3">
                      <button onClick={handleIncExp} type="button" className="btn btn-success">
                        <i className="fas fa-plus"></i>
                      </button>
                    </div>
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={() => setOpenExpDrawer(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary"
                      type="submit"
                      onClick={handleExperience}
                    >
                      Submit
                    </button>

                  </div>
                </div>
              </form>
              {/* )
                }
                }
              </Formik> */}
            </div>
          </Box>
        </Drawer>
      </div>
    );
  };

  const EmployeeEducationTab = () => {
    const [openEmpEduDrawer, setOpenEmpEduDrawer] = useState(false);
    const [empEducation, setEmpEducation] = useState([]);
    const [drawerHead, setDrawerHead] = useState("")
    const getEmpEducation = async () => {
      await Axios.get(`${ApiConfig.getEducation}&employee_id=${EmployeData?.employee_id}`).then((res) => {
        // console.log(res)
        setEmpEducation(res?.data?.data)
      }).catch((err) => {
        // console.log(err)
      })
    }
    useEffect(() => {
      getEmpEducation();
    }, []);

    const [eduData, setEduData] = useState("")
    useMemo(() => !openEmpEduDrawer ? setEduData("") : null, [openEmpEduDrawer])
    const initialField = {
      education_institute: eduData?.education_institute || "",
      education_degree: eduData?.education_degree || "",
      education_field: eduData?.education_field || "",
      education_mark: eduData?.education_mark || "",
      education_start: moment.utc(eduData?.education_start).format("YYYY-MM-DD") || "",
      education_end: moment.utc(eduData?.education_end).format("YYYY-MM-DD") || "",
    }
    const handleAddEducation = async (value) => {
      await Axios.post(ApiConfig.addEducation, { employee_id: EmployeData?.employee_id, ...value }, {
        headers: {
          Authorization: "Bearer " + token,
        }
      }).then((res) => {
        // console.log(res)
        if (res.data.code === "200") {
          getEmpEducation();
          toast.success(res.data.message);
          setOpenEmpEduDrawer()
        } else {
          toast.error(res.data.message);
        }
      }).catch((err) => {
        toast.error(err.response.data.message);
      })
    }
    const handleEditEducation = async (val) => {
      await Axios.post(ApiConfig.updateEducation, { education_id: eduData?.education_id, ...val }, {
        headers: {
          Authorization: "Bearer " + token,
        }
      }).then((res) => {
        // console.log(res)
        if (res.data.code === "200") {
          getEmpEducation();
          toast.success(res.data.message);
          setOpenEmpEduDrawer();
        } else {
          toast.error(res.data.message);
        }
      }).catch((err) => {
        toast.error(err.response.data.message)
      })
    }
    // console.log(degreeConfig)
    return (
      <div>
        <Box style={{ padding: "15px" }}>
          <div className="modal-content">
            <div style={{ justifyContent: "end" }} className="modal-header">
              <div className="btnConteiner">
                <button onClick={() => setOpenEmpEduDrawer(true)} className="btn btn-primary">
                  +Add Education
                </button>
              </div>
            </div>
          </div>
        </Box>
        <Box>
          <div className="card container-lg container-main">
            <div className="table-responsive">
              <table className="table table-vcenter card-table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Institute Name</th>
                    <th>Degree</th>
                    <th>Field</th>
                    <th>Marks</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {empEducation?.length === 0 && <p className="mx-2">No Education Found</p>}
                  {
                    empEducation?.map((val, i) => {
                      // console.log(val)
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{val?.education_institute}</td>
                          <td>{degreeConfig[val?.education_degree]?.degree}</td>
                          <td>{val?.education_field}</td>
                          <td>{val?.education_mark}%</td>
                          <td>{moment.utc(val?.education_start).format("DD-MM-YYYY")}</td>
                          <td>{moment.utc(val?.education_end).format("DD-MM-YYYY")}</td>
                          <td><i onClick={() => { setEduData(val); setDrawerHead("edit"); setOpenEmpEduDrawer(true) }} className="fas fa-edit text-success"></i></td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </Box>
        <Drawer anchor="right" className="modal-blur" open={openEmpEduDrawer} onClose={() => { setOpenEmpEduDrawer(false); setDrawerHead("") }}>
          <Box width="40rem">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add Employee Education</h5>
              </div>

              <Formik initialValues={initialField}
                initialTouched={{
                  field: true,
                }}
                validationSchema={addEducation}
                enableReinitialize
                onSubmit={(values) => {
                  if (drawerHead === "edit") {
                    return handleEditEducation(values)
                  } else {
                    return handleAddEducation(values)
                  }
                }
                }
              >
                {({ errors, handleBlur, handleChange, touched, values }) => {
                  // console.log(errors);
                  return (
                    <Form>
                      <div className="modal-body">
                        <div className="container">
                          <div className="row">
                            <div className="col-md-6 mb-3">
                              <label className="form-label" htmlFor="institute">Institute Name</label>
                              <input type="text" className="form-control" id="institute" name="education_institute" onChange={handleChange} onBlur={handleBlur} value={values?.education_institute} placeholder="Enter Organisation Name" />
                              {errors?.education_institute && touched.education_institute ? <p className="form-valid">{errors?.education_institute}</p> : null}
                            </div>
                            <div className="col-md-6">
                              <label className="form-label" htmlFor="degree">Degree Type</label>
                              <select className="form-select" id="degree" onChange={handleChange} onBlur={handleBlur} value={values?.education_degree} name="education_degree" aria-label="Default select example">
                                <option selected>Choose Degree Type</option>
                                {
                                  degreeConfig?.map((val, i) => {
                                    return (
                                      <option key={i} value={val?.id}>{val?.degree}</option>
                                    )
                                  })
                                }
                              </select>
                              {errors?.education_degree && touched.education_degree ? <p className="form-valid">{errors?.education_degree}</p> : null}
                            </div>
                            <div className="col-md-6">
                              <label className="form-label" htmlFor="field">Education Field</label>
                              <input type="text" onChange={handleChange} id="field" onBlur={handleBlur} value={values?.education_field} name="education_field" className="form-control" placeholder="Enter Job Title" />
                              {errors?.education_field && touched.education_field ? <p className="form-valid">{errors?.education_field}</p> : null}
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="form-label" htmlFor="mark">Mark</label>
                              <input type="text" onChange={handleChange} id="mark" onBlur={handleBlur} value={values?.education_mark} name="education_mark" className="form-control" placeholder="Enter Job Title" />
                              {errors?.education_mark && touched.education_mark ? <p className="form-valid">{errors?.education_mark}</p> : null}
                            </div>
                            <div className="col-md-6">
                              <label className="form-label" htmlFor="edu_start_date">Start Date</label>
                              <input type="date" onChange={handleChange} id="edu_start_date" onBlur={handleBlur} value={values?.education_start} name="education_start" className="form-control" />
                              {errors?.education_start && touched.education_start ? <p className="form-valid">{errors?.education_start}</p> : null}
                            </div>
                            <div className="col-md-6">
                              <label className="form-label" htmlFor="edu_end_date">End Date</label>
                              <input type="date" onChange={handleChange} id="edu_end_date" onBlur={handleBlur} value={values?.education_end} name="education_end" className="form-control" />
                              {errors?.education_end && touched.education_end ? <p className="form-valid">{errors?.education_end}</p> : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={() => setOpenEmpEduDrawer(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn btn-primary"
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>
                    </Form>
                  )
                }
                }
              </Formik>
            </div>
          </Box>
        </Drawer>
      </div>
    );
  };
  const EmployeeDocumentTab = () => {
    const [uploadDrawer, setUploadDrawer] = useState(false);
    const [empDocument, setEmpDocument] = useState([]);
    const getEmpDocument = async () => {
      await Axios.get(`${ApiConfig.getFileByUser}&employee_id=${EmployeData?.employee_id}`).then((res) => {
        // console.log(res)
        setEmpDocument(res?.data?.data)
      }).catch((err) => {
        // console.log(err)
      })
    }
    useEffect(() => {
      getEmpDocument();
    }, []);
    const [file, setFile] = useState(null);
    const [fileTypes, setFileTypes] = useState("")
    const handleUpload = (e) => {
      // console.log(e.target.files)
      setFile(e.target.files[0])
    }
    // console.log(file)
    const [fileLoader, setFileLoader] = useState(false)
    const handleDocument = async (e) => {
      setFileLoader(true);
      e.preventDefault();
      if (file !== null) {
        const formData = new FormData()
        formData.append("fileType", fileTypes);
        formData.append("employee_id", EmployeData?.employee_id);
        formData.append("file", file);
        await Axios.post(ApiConfig.upload, formData, {
          headers: {
            Authorization: "Bearer " + token
          }
        }).then((res) => {
          // console.log(res)
          if (res?.status === 201) {
            setFileLoader();
            setUploadDrawer(false);
            getEmpDocument();
            toast.success(res?.data?.message)
          }
        }).catch((err) => {
          // console.log(err)
          setFileLoader();
          toast.error(err?.response?.data?.message)
        })
      } else {
        toast.error("Please Upload a File !!!")
      }
    }
    const [openFileTypes, setOpenFileTypes] = useState(false);
    const [getFileType, setGetFileType] = useState([]);
    const getFileTypes = async () => {
      await Axios.get(ApiConfig.getFileTypes, {
        headers: {
          Authorization: "Bearer " + token
        }
      }).then((res) => {
        // console.log(res)
        if (res?.data?.code === "200") {
          setGetFileType(res?.data?.data)
        }
      }).catch((err) => {
        // console.log(err)
      })
    }
    useEffect(() => {
      getFileTypes();
    }, []);

    const [fileName, setFileName] = useState("");
    const handleFileType = async () => {
      await Axios.post(ApiConfig.addFileType, { "document_type_name": fileName }, {
        headers: {
          Authorization: "Bearer " + token
        }
      }).then((res) => {
        // console.log(res)
        if (res?.data?.code === "200") {
          getFileTypes();
          setOpenFileTypes();
          toast.success(res?.data?.message);
        }
      }).catch((err) => {
        // console.log(err)
      })
    }
    return (
      <div>
        <Box style={{ padding: "15px" }}>
          <div className="modal-content">
            <div style={{ justifyContent: "end" }} className="modal-header">
              <div className="btnConteiner">
                <button onClick={() => setUploadDrawer(true)} className="btn btn-primary mx-3">
                  +Upload Document
                </button>
                <button onClick={() => setOpenFileTypes(true)} className="btn btn-success">
                  +Add File Type
                </button>
              </div>
            </div>
          </div>
        </Box>
        <Box>
          <div className="card container-lg container-main">
            <div className="table-responsive">
              <table className="table table-vcenter card-table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>File Type</th>
                    <th>File</th>
                    <th>File Status</th>
                  </tr>
                </thead>
                <tbody>
                  {empDocument?.length === 0 && <p className="mx-2">No File Found</p>}
                  {
                    empDocument?.map((val, i) => {
                      // console.log(val)
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>
                            {
                              getFileType?.map((type, i) => {
                                return (
                                  val?.file_type === type?.document_type_id &&
                                  <span key={i}>{type?.document_type_name}</span>
                                )
                              })
                            }
                          </td>
                          <td><img style={{ width: "20%" }} src={val?.file_path} alt={val?.file_name} /></td>
                          <td>{val?.file_status}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </Box >
        <Drawer anchor="right" className="modal-blur" open={uploadDrawer} onClose={() => setUploadDrawer(false)}>
          <Box width="40rem">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add Employee Document</h5>
              </div>
              <form>
                <div className="modal-body">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="document">Document Type</label>
                        <select className="form-select" id="document" name="fileType" value={fileTypes} onChange={(e) => setFileTypes(e.target.value)}>
                          <option selected>Choose Document Type</option>
                          {
                            getFileType?.map((val, i) => {
                              return (
                                <option key={i} value={val?.document_type_id}>{val?.document_type_name}</option>
                              )
                            })
                          }
                        </select>

                      </div>
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="file">Upload Document</label>
                        <input type="file" className="form-control" id="file" accept="image/*" onChange={handleUpload} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => setUploadDrawer(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary"
                    type="submit"
                    onClick={handleDocument}
                    disabled={fileLoader}
                  >
                    {fileLoader ? "Uploading..." : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </Box>
        </Drawer>
        <Drawer anchor="right" className="modal-blur" open={openFileTypes} onClose={() => setOpenFileTypes(false)}>
          <Box width="40rem">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add Document File Type</h5>
              </div>
              <div className="container my-3">
                <div className="row">
                  <div className="col-md-8">
                    <input type="text" id="file-type" placeholder="Enter File Type" onChange={(e) => setFileName(e.target.value)} className="form-control" />
                  </div>
                  <div className="col-md-4">
                    <input type="submit" className="btn btn-primary" onClick={handleFileType} />
                  </div>
                </div>
              </div>
              <div className="card container-lg container-main">
                <div className="table-responsive">
                  <table className="table table-vcenter card-table">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Document Name</th>
                        <th>Document Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        getFileType?.map((val, i) => {
                          return (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{val?.document_type_name}</td>
                              <td>{val?.document_type_status === 1 ? <button className="btn btn-success">Active</button> : <button className="btn btn-danger">Inactive</button>}</td>
                              <td><i className="fas fa-edit text-success"></i></td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Box>
        </Drawer>
      </div >
    );
  };
  return (
    <div>
      <Dashboard>
        <PageHead title={`Attendify || ${EmployeData?.employee_name}`} />
        <div className="page">
          <div className="page-wrapper" style={{ padding: "0px 10px" }}>
            <div className="container-lg container-main">
              <div className="page-header d-print-none mb-3">
                <div
                  style={{
                    display: "flex",
                    flexwrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h2 className="">{`${EmployeData?.employee_name} (${EmployeData?.employee_no})`}</h2>
                </div>
              </div>
            </div>
            <div className="btnContainer">
              {/* <button
                className={buttontab === "Details" ? "btn btn-primary" : "btn"}
                onClick={() => {
                  setButtontab("Details");
                }}
              >
                Employee Details
              </button> */}

              <button
                className={buttontab === "Experience" ? "btn btn-primary" : "btn"}
                onClick={() => {
                  setButtontab("Experience");
                }}
              >
                Employee Experience
              </button>
              <button
                className={buttontab === "Education" ? "btn btn-primary" : "btn"}
                onClick={() => {
                  setButtontab("Education");
                }}
              >
                Employee Education
              </button>
              <button
                className={buttontab === "Documentation" ? "btn btn-primary" : "btn"}
                onClick={() => {
                  setButtontab("Documentation");
                }}
              >
                Employee Documentation
              </button>
              <button
                className={buttontab === "Leave" ? "btn btn-primary" : "btn"}
                onClick={() => {
                  setButtontab("Leave");
                }}
              >
                Employee Leave
              </button>
            </div>
            <div className="card" style={{ marginTop: "15px", padding: "10px" }}>
              {buttontab === "Leave" ? <EmployeeLeaveTab /> : buttontab === "Experience" ? <EmployeeExperinceTab /> : buttontab === "Education" ? <EmployeeEducationTab /> : buttontab === "Documentation" ? <EmployeeDocumentTab /> : null}
            </div>
          </div>
        </div>
      </Dashboard>
    </div>
  );
};

export default EmployeeView;
