import React, { useState, useEffect, useMemo } from "react";
import Dashboard from "./Dashboard";
import { useParams, useNavigate, Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Axios from "axios";
import { toast } from "react-toastify";
import ApiConfig from "../Config/APIConfig";
import { AiOutlinePlus } from "react-icons/ai";
import { Drawer, Box } from "@mui/material";
import PageHead from "./PageHead";
import { Form, Formik, Field } from "formik";
import { addEmployee } from "./schema";
import axios from "axios";
import moment from "moment";
const Home = () => {
  // console.log(Formik)
  const navigate = useNavigate();
  const { employee_company } = useParams();
  // console.log(employee_company);
  const [token, setToken] = useState("");
  useEffect(() => {
    setToken(JSON.parse(localStorage.getItem("Att_token")));
  }, []);
  // Refab Data
  const [getEmpData, setgetEmpData] = useState([]);
  const [count, setCount] = useState("");
  const [page, setPage] = useState(1);
  const limit = 10;
  const getEmployee = async () => {
    let lastPage = false;
    if (count !== "") {
      lastPage = parseInt(count / limit + 1);
      // console.log(lastPage, page)
    }
    await Axios.get(`${ApiConfig.getEmployeeT}&employee_company=${employee_company}&pagination=true&limit=${lastPage === page ? count % limit : limit}&offset=${page}`)
      .then((res) => {
        setgetEmpData(res.data.data);
        setCount(res.data.count);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getEmployee();
  }, [employee_company, page]);

  useMemo(() => {
    setPage(1);
  }, [employee_company]);

  //company List
  const [companyList, setCompanyList] = useState([]);
  const getCompany = async () => {
    await Axios.get(ApiConfig.getCompanies, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        res?.data?.code === "200" ? setCompanyList(res?.data?.data) : setCompanyList([]);
      })
      .catch((err) => {
        throw err;
      });
  };
  useMemo(() => {
    if (token) getCompany();
  }, [token]);
  // console.log(value === "")
  const [getBranch, setGetBranch] = useState([]);
  const getBranchDetails = async (value) => {
    if (token) {
      await Axios.get(`${ApiConfig.getBranches}&company_id=${value === undefined ? employee_company : value}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          // console.log(res)
          res?.data?.code === "200" ? setGetBranch(res?.data?.data) : setGetBranch([]);
        })
        .catch((err) => {
          throw err;
        });
    }
  };
  useEffect(() => {
    getBranchDetails();
  }, [token, employee_company]);
  const [branchInput, setBranchInput] = useState("");
  // console.log(branchInput)
  // Designation List
  const [getDesg, setGetDesg] = useState([]);
  const getDesignation = async () => {
    try {
      const res = await Axios.post(ApiConfig.getDesignationT, {
        // designation_status: employee_company,
        designation_status: 1,
        pagination: true,
        limit: 10,
      });
      if (res.status == 200) {
        // console.log("asdasfgdfshd", res);
        setGetDesg(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDesignation();
  }, []);
  const [data, setData] = useState("");
  // console.log(data)
  const [drawerHead, setDrawerHead] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const initialValue = {
    employee_name: data?.employee_name || "",
    employee_email: data?.employee_email || "",
    employee_gender: data?.employee_gender || "",
    employee_dob: data?.employee_dob || "",
    employee_service_email: data?.employee_service_email || "",
    employee_mobile: data?.employee_mobile || "",
    employee_designation: data?.employee_designation || "",
    employee_company: data?.employee_company || employee_company,
    employee_branch_type: data?.employee_branch_type || "",
    employee_home_address: data?.employee_home_address || "",
    employee_office_address: data?.employee_office_address || "",
    employee_present_address: data?.employee_present_address || "",
    employee_type: data?.employee_type || "",
    employee_password: data?.employee_password || "",
    employee_probation_start: moment.utc(data?.employee_probation_start).format("YYYY-MM-DD") || "",
    employee_probation_end: moment.utc(data?.employee_probation_end).add(6, "months").format("YYYY-MM-DD") || "",
  };
  const AddEmployee = async (values) => {
    try {
      const res = await Axios.post(`${ApiConfig.addEmployee}`, values, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (res.status === 200) {
        getEmployee();
        toast.success("Employee Added Successfully");
        setOpen();
      }
    } catch (error) {
      if (error.response.data.code === "404") {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.response.data.message);
      }
    }
  };

  const [departList, setDeprtList] = useState();
  const DepartmentList = async () => {
    try {
      const res = await Axios.post(ApiConfig.departmentList, {
        // department_status: employee_company,
        department_status: 1,
        pagination: true,
        limit: 10,
      });
      if (res.status === 200) {
        // console.log("sdfsgfsd", res);
        setDeprtList(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    DepartmentList();
  }, []);
  const EditEmployee = async (val) => {
    await axios
      .post(
        ApiConfig.employeeEditDetails,
        { ...val, employee_id: data?.employee_id },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        // console.log(res)
        if (res.data.code === "200") {
          getEmployee();
          toast.success("Employee Update Successfully");
          setOpen();
        } else {
          toast.error("Employee Update Failed");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
  useMemo(() => (!open ? setData("") : null), [open]);
  const getEmpByBranch = async () => {
    let lastPage = false;
    if (count !== "") {
      lastPage = parseInt(count / limit + 1);
      // console.log(lastPage, page)
    }
    await axios.get(`${ApiConfig.getEmployeesByBranch}&pagination=true&limit=${lastPage === page ? count % limit : limit}&page=${page}&branch_id=${branchInput}`, {
      headers: {
        Authorization: "Bearer " + token
      }
    }).then((res) => {
      // console.log(res)
      if (res.status === 200) {
        setgetEmpData(res?.data?.data)
      }
    }).catch((err) => {
      console.log(err)
    })
  }
  useEffect(() => {
    if (branchInput) getEmpByBranch();
  }, [branchInput, page]);
  useMemo(() => {
    setPage(1);
  }, [branchInput]);

  return (
    <div>
      <Dashboard>
        <PageHead title="Attendify || Employes" />
        <div className="page">
          <div className="page-wrapper" style={{ padding: "0px 10px" }}>
            <div className="container-lg container-main mb-3">
              <div className="page-header d-print-none">
                <div
                  style={{
                    display: "flex",
                    flexwrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      marginRight: "15px",
                    }}
                  >
                    <h2 className="">
                      {employee_company === "1"
                        ? "Recharge Kit Pvt.Ltd"
                        : null || employee_company === "2"
                          ? "Refab Electrochip Pvt.Ltd"
                          : null || employee_company === "3"
                            ? "Renxt DigitalFilm Pvt.Ltd"
                            : null || employee_company === "4"
                              ? "Qubeinfo Pvt.Ltd"
                              : null}
                    </h2>
                  </div>
                  <div className="d-flex">
                    <select className="form-select mx-4" onChange={(e) => navigate(`/home/${e.target.value}`)} value={employee_company}>
                      {companyList?.map((val, i) => {
                        return (
                          <option key={i} value={val?.company_id}>
                            {val?.company_name}
                          </option>
                        );
                      })}
                    </select>
                    <select className="form-select" onChange={(e) => setBranchInput(e.target.value)} value={branchInput}>
                      <option selected>Choose Branch</option>
                      {getBranch?.map((val, i) => {
                        return (
                          <option key={i} value={val?.branch_id}>
                            {val?.branch_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="d-print-none">
                    <div className="btn-list">
                      <div
                        onClick={() => {
                          handleOpen();
                          setDrawerHead("Add");
                        }}
                        className="btn btn-primary d-none d-sm-inline-block"
                      >
                        <AiOutlinePlus />
                        &nbsp; Add Empolyee
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card container-lg container-main">
              <div className="table-responsive">
                <table className="table table-vcenter table-mobile-md card-table">
                  <thead>
                    <tr>
                      <th>employee no</th>
                      <th>employee name</th>
                      <th>employee mobile</th>
                      <th>employee service email</th>
                      <th>employee gender</th>
                      <th>employee dob</th>
                      <th>Company Name</th>
                      <th>department name</th>
                      <th>designation name</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getEmpData === 0 && "No data found"}
                    {getEmpData &&
                      getEmpData?.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td data-label="Name">
                              <div className="d-flex py-1 align-items-center">
                                <div className="flex-fill">
                                  <div className="font-weight-medium">{item.employee_no}</div>
                                </div>
                              </div>
                            </td>
                            <td data-label="Title">{item.employee_name}</td>
                            <td className="text" data-label="Mobile">
                              {item.employee_mobile}
                            </td>
                            <td className="text" data-label="Service Email">
                              {item.employee_service_email}
                            </td>
                            <td className="text" data-label="Gender">
                              {item.employee_gender === 1 ? "Male" : "Female"}
                            </td>
                            <td className="text" data-label="Dob">
                              {item.employee_dob}
                            </td>
                            <td className="text" data-label="Company">
                              {item.employee_branch_type === 1 ? "Recharge Kit" : null || item.employee_branch_type === 2 ? "Refab" : null || item.employee_branch_type === 3 ? "Renxt" : null}
                            </td>
                            <td className="text" data-label="Department">
                              {item.department_name}
                            </td>
                            <td className="text" data-label="Designation">
                              {item.designation_name}
                            </td>
                            <td>
                              <div className="btn-list flex-nowrap">
                                <span
                                  onClick={() => {
                                    setOpen(true);
                                    setData(item);
                                    setDrawerHead("Edit");
                                  }}
                                  className="btn"
                                >
                                  Edit
                                </span>
                                <Link to="/emp_view" state={item} className="btn">
                                  View
                                </Link>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="card-footer d-flex justify-content-end">{getEmpData.length !== 0 && <Pagination color="primary" count={Math.ceil(count / limit)} onChange={(e, value) => setPage(value)} />}</div>
            </div>
            {console.log(count)}
            {/* Model start */}
            <Drawer anchor="right" className="modal-blur" open={open} onClose={() => setOpen(false)}>
              <Box width="40rem">
                <div className="modal-header">
                  <h5 className="modal-title">Add Empolyee</h5>
                </div>
                <Formik
                  initialValues={initialValue}
                  initialTouched={{
                    field: true,
                  }}
                  validationSchema={addEmployee}
                  enableReinitialize
                  onSubmit={(values) => {
                    if (drawerHead === "Add") {
                      AddEmployee(values);
                    } else {
                      EditEmployee(values);
                    }
                  }}
                >
                  {({ errors, handleBlur, handleChange, touched, values }) => {
                    return (
                      <Form>
                        <div className="modal-body">
                          <div className="form-selectgroup-boxes row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">CHOOSE COMPANY</label>
                                <Field
                                  as="select"
                                  className="form-select"
                                  disabled
                                  name="employee_company"
                                  onChange={(e) => {
                                    handleChange(e);
                                    getBranchDetails(e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                  value={employee_company}
                                >
                                  {companyList?.map((val, i) => {
                                    // console.log(val)
                                    return (
                                      <option selected key={i} value={val?.company_id}>
                                        {val?.company_name}
                                      </option>
                                    );
                                  })}
                                </Field>
                                {errors?.employee_company && touched.employee_company ? <p className="form-valid">{errors?.employee_company}</p> : null}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">CHOOSE BRANCH</label>
                                <Field as="select" className="form-select" name="employee_branch_type" onChange={handleChange} onBlur={handleBlur} value={values?.employee_branch_type}>
                                  <option value="" selected>
                                    Select
                                  </option>
                                  {getBranch?.map((val, i) => {
                                    return (
                                      <option key={i} value={val?.branch_id}>
                                        {val?.branch_name}
                                      </option>
                                    );
                                  })}
                                </Field>
                                {errors?.employee_branch_type && touched.employee_branch_type ? <p className="form-valid">{errors?.employee_branch_type}</p> : null}
                              </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                              <label className="form-label">EMPLOYEE NAME</label>
                              <Field type="text" className="form-control" name="employee_name" placeholder="employee_name" onChange={handleChange} onBlur={handleBlur} value={values?.employee_name} />
                              {errors?.employee_name && touched.employee_name ? <p className="form-valid">{errors?.employee_name}</p> : null}
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">EMPLOYEE SERVICE EMAIL</label>
                                <div className="input-group input-group-flat">
                                  <Field
                                    type="email"
                                    className="form-control"
                                    name="employee_service_email"
                                    placeholder="employee_service_emaill"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values?.employee_service_email}
                                  />
                                </div>
                                {errors?.employee_service_email && touched.employee_service_email ? <p className="form-valid">{errors?.employee_service_email}</p> : null}
                              </div>
                            </div>
                          </div>
                          <div className="form-selectgroup-boxes row mb-3">
                            <div className="col-lg-6 mb-3">
                              <label className="form-label">EMPLOYEE MOBILE</label>
                              <Field type="number" className="form-control" name="employee_mobile" placeholder="employee_mobile" onChange={handleChange} onBlur={handleBlur} value={values?.employee_mobile} />
                              {errors?.employee_mobile && touched.employee_mobile ? <p className="form-valid">{errors?.employee_mobile}</p> : null}
                            </div>

                            <div className="col-lg-6">
                              <label className="form-label">EMPLOYEE EMAIL</label>
                              <Field type="email" className="form-control" name="employee_email" placeholder="employee_email" onChange={handleChange} onBlur={handleBlur} value={values?.employee_email} />
                              {errors?.employee_email && touched.employee_email ? <p className="form-valid">{errors?.employee_email}</p> : null}
                            </div>
                          </div>
                          <div className="row">
                            {drawerHead !== "Edit" && (
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label className="form-label">EMPLOYEE PASSWORD</label>
                                  <div className="input-group input-group-flat">
                                    <Field
                                      type="password"
                                      className="form-control"
                                      name="employee_password"
                                      placeholder="employee_password"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values?.employee_password}
                                    />
                                  </div>
                                  {errors?.employee_password && touched.employee_password ? <p className="form-valid">{errors?.employee_password}</p> : null}
                                </div>
                              </div>
                            )}
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">EMPLOYEE GENDER</label>
                                <Field as="select" className="form-select" name="employee_gender" onChange={handleChange} onBlur={handleBlur} value={values?.employee_gender}>
                                  <option value="">Select</option>
                                  <option value="1">Male</option>
                                  <option value="2">Female</option>
                                  {/* <option value="3">Hidden</option> */}
                                </Field>
                                {errors?.employee_gender && touched.employee_gender ? <p className="form-valid">{errors?.employee_gender}</p> : null}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="modal-body">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">EMPLOYEE DEPARTMENT</label>
                                <Field as="select" className="form-select" name="employee_type" onChange={handleChange} onBlur={handleBlur} value={values?.employee_type}>
                                  <option value="">Select</option>
                                  {departList &&
                                    departList.map((item, i) => {
                                      return (
                                        <option key={i} value={item.department_id}>
                                          {item.department_name}
                                        </option>
                                      );
                                    })}
                                </Field>
                                {errors?.employee_type && touched.employee_type ? <p className="form-valid">{errors?.employee_type}</p> : null}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">EMPLOYEE DESIGNATION</label>
                                <Field as="select" className="form-select" name="employee_designation" onChange={handleChange} onBlur={handleBlur} value={values?.employee_designation}>
                                  <option value="">Select</option>
                                  {getDesg
                                    ?.filter((val) => val?.department_id == values?.employee_type)
                                    .map((item, i) => {
                                      // console.log(item)
                                      return (
                                        <option key={i} value={item?.designation_id}>
                                          {item?.designation_name}
                                        </option>
                                      );
                                    })}
                                </Field>
                                {errors?.employee_designation && touched.employee_designation ? <p className="form-valid">{errors?.employee_designation}</p> : null}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label className="form-label">EMPLOYEE PROBATION START</label>
                                <Field type="date" className="form-control" name="employee_probation_start" onChange={handleChange} onBlur={handleBlur} value={values?.employee_probation_start} />
                                {errors?.employee_probation_start && touched.employee_probation_start ? <p className="form-valid">{errors?.employee_probation_start}</p> : null}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label className="form-label">EMPLOYEE PROBATION END</label>
                                <Field type="date" className="form-control" name="employee_probation_end" onChange={handleChange} onBlur={handleBlur} value={values?.employee_probation_end} />
                                {errors?.employee_probation_end && touched.employee_probation_end ? <p className="form-valid">{errors?.employee_probation_end}</p> : null}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">EMPLOYEE DOB</label>
                                <Field type="date" className="form-control" name="employee_dob" onChange={handleChange} onBlur={handleBlur} value={values?.employee_dob} />
                                {errors?.employee_dob && touched.employee_dob ? <p className="form-valid">{errors?.employee_dob}</p> : null}
                              </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                              <div>
                                <label className="form-label">Employee Home Address</label>
                                <textarea className="form-control" rows="3" name="employee_home_address" onChange={handleChange} onBlur={handleBlur} value={values?.employee_home_address}></textarea>
                                {errors?.employee_home_address && touched.employee_home_address ? <p className="form-valid">{errors?.employee_home_address}</p> : null}
                              </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                              <div>
                                <label className="form-label">Employee Office Address</label>
                                <textarea className="form-control" rows="3" name="employee_office_address" onChange={handleChange} onBlur={handleBlur} value={values?.employee_office_address}></textarea>
                                {errors?.employee_office_address && touched.employee_office_address ? <p className="form-valid">{errors?.employee_office_address}</p> : null}
                              </div>
                            </div>
                            <div className="col-lg-6 mb3">
                              <div>
                                <label className="form-label">Employee Present Address</label>
                                <textarea className="form-control" rows="3" name="employee_present_address" onChange={handleChange} onBlur={handleBlur} value={values?.employee_present_address}></textarea>
                                {errors?.employee_present_address && touched.employee_present_address ? <p className="form-valid">{errors?.employee_present_address}</p> : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button onClick={() => setOpen()} className="btn btn-link link-secondary">
                            Cancel
                          </button>
                          <button className="btn btn-primary ms-auto" type="submit">
                            Submit
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </Box>
            </Drawer>
          </div>
        </div>
      </Dashboard>
    </div>
  );
};

export default Home;
