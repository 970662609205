import React, { useState, useEffect } from "react";
import DataContext from "./Context";

const ContextState = (props) => {
  const [loginData, setLoginData] = useState(null);
  const [loginCounter, setLoginCounter] = useState(0);
  useEffect(() => {
    setLoginData(JSON.parse(localStorage.getItem("Employee_Details")))
  }, [loginCounter]);

  return (
    <DataContext.Provider
      value={{
        loginData,
        setLoginData,
        loginCounter,
        setLoginCounter,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};

export default ContextState;
