import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Attendance from "./components/Attendance";
import Home from "./components/Home";
import PrivateRoutes from "./Context/AuthContext";
import CandidateEnroll from "./components/Candidate_Management/Candidate";
import ScrollButton from "./components/ScrollTopBtn/ScrollBtn";
import HomeDash from "./components/HomeDash";
import ForgetPassword from "./components/ForgetPassword";
import ContextState from "./Context/StateContext";
import CandidateLogin from "./components/CadidateLogin";
import PageError from "./components/PageError";
import Updatepassword from "./components/Updatepassword";
import LeaveMgmt from "./components/LeaveMgmt";
import Company from "./components/company_management/Company"
import EmployeeView from "./components/EmployeeView";
import LeaveCategory from "./components/LeaveCategory";
import Internship from "./components/Candidate_Management/Internship";
function App() {
  // console.log(user)
  const [user, setUser] = useState(null);
  const checkUser = () => {
    if (JSON.parse(localStorage.getItem("Employee_Details"))) {
      setUser(JSON.parse(localStorage.getItem("Employee_Details")))
      return <Navigate to="/homeDash" />
    } else {
      setUser(null)
    }
  }
  useEffect(() => {
    checkUser()
  }, []);
  return (
    <div style={{ height: "100%" }}>
      <ContextState>
        <BrowserRouter>
          <Routes>
            <Route path="/forgetpassword" element={<ForgetPassword />} />
            <Route path="/employeelogin" element={<CandidateLogin />} />
            <Route exact path="/" element={!user || user === null ? <CandidateLogin /> : <HomeDash />} />
            <Route path="/login" element={<CandidateLogin />} />
            <Route element={<PrivateRoutes />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/updatepassword" element={<Updatepassword />} />
              <Route path="/homeDash" element={<HomeDash />} />
              <Route path="/candidateEnroll" element={<CandidateEnroll />} />
              <Route path="/internship" element={<Internship />} />
              <Route path="/attendance" element={<Attendance />} />
              <Route path="/emp_view" element={<EmployeeView />} />
              <Route path="/leavemgmt" element={<LeaveMgmt />} />
              <Route path="/company_management" element={<Company />} />
              <Route path="/leaveCategory" element={<LeaveCategory />} />
              <Route path="/home/:employee_company" element={<Home />} />
            </Route>
            <Route path="*" element={<PageError />} />
          </Routes>
        </BrowserRouter>
      </ContextState>
      <ScrollButton />
    </div>
  );
}

export default App;
